import {PossibleTitles} from "./types";
import I18n from "i18next";

let isAttemptingCall = false;
let isNewMessage = false;

export const setAttemptingCall = (state: boolean) => {
    isAttemptingCall = state;
    renderTitle();
    renderFavIcon();
};

export const setIsNewMessage = (state: boolean) => {
    isNewMessage = state;
    renderTitle();
    renderFavIcon();
};

const renderTitle = () => {
    if (isAttemptingCall) {
        window.document.title = '* ' + I18n.t('app:incomingCall') + ' *';
        return;
    }

    if (isNewMessage) {
        window.document.title = '* ' + I18n.t('app:newMessage') + ' *';
        return;
    }
    window.document.title = PossibleTitles.DEFAULT;
};

const renderFavIcon = () => {
    let link = document.querySelector("link[rel*='icon']");

    if (isAttemptingCall) {
        // @ts-ignore
        link.href = '/favicon_calling.ico';
        return;
    }

    if (isNewMessage) {
        // @ts-ignore
        link.href = '/favicon_message.ico';
        return;
    }
    // @ts-ignore
    link.href = '/favicon.ico';
    return;
}






