import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import pl from './pl.json';
import en from './en.json';
import cz from './cz.json';
import ua from './ua.json';

i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        debug: true,
        resources: {
            en: en,
            pl: pl,
            ua: ua,
            cz: cz
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;
