export interface IProps {

}

export interface chosenLanguage {
    value: string,
    label: string
}

export interface IState {
    chosenLanguage: {
        value: string,
        label: string
    }
}

export const Languages = [
    {value: 'en', label: 'English'},
    {value: 'pl', label: 'Polski'},
    {value: 'ua', label: 'український'},
    {value: 'cz', label: 'Český'}
];
