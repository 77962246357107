export const populateAttachments = (messages: any) => {
    let attachments: any[] = [];
    if (messages) {
        messages.forEach((item: any) => {
            if (item.attachment) {
                let attachment = prepareAttachment(item);
                attachments.push(attachment);
            }
        })
    }
    return attachments;
};

export const prepareAttachment = (message: any) => {
    return {
        content: message.content,
        id: message.attachment.id,
        uuid: message.attachment.uuid,
        filename: message.attachment.filename,
        title: message.attachment.title,
        participant: message.participant,
        createdAt: message.createdAt
    };
};