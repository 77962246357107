import React, {useState} from 'react'
import {RadioButtons} from "./RadioButtons";
import {useTranslation} from "react-i18next";
import I18n from "i18next";
import {send} from "../../utils/reviews";
import GoogleReviewsButton from "./GoogleReviewsButton";

export const CallReview = (props: {
    token: string,
    room: string,
    participant: string,
    callId: number,
    issues: { audio: string[], video: string[] },
    dismiss: () => void
}) => {
    useTranslation()
    const [step, setStep] = useState(1);
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [audioIssues, setAudioIssues] = useState<string[]>([]);
    const [videoIssues, setVideoIssues] = useState<string[]>([]);
    const [audioIssueDescription, setAudioIssueDescription] = useState("");
    const [videoIssueDescription, setVideoIssueDescription] = useState("");
    const [networkType, setNetworkType] = useState("");
    const prepareArray = (array: string[], value:string) => {
        const index = array.indexOf(value);
        if (index >= 0) {
            array.splice(index, 1);
        } else {
            array.push(value);
        }
        return array;
    }

    const onSubmit = () => {
        const allowSubmit = (step === 1 && rating === 5) || step === 4;

        if (!allowSubmit && step < 4) {
            setStep(step + 1);
            return;
        }

        if (!allowSubmit) {
            return;
        }

        setStep(4);
        send(props.token, props.room, props.participant, {
            callId: props.callId,
            rate: rating,
            audioIssues: audioIssues,
            videoIssues: videoIssues,
            audioIssueDescription: audioIssueDescription,
            videoIssueDescription: videoIssueDescription,
            userAgent: networkType + '_' + navigator.userAgent
        }).then(response => {
            setTimeout(() => {
                props.dismiss();
                setStep(1);
            }, 3000)
        })
    }

    const onCancel = () => {
        if (step > 1 && step !== 4) {
            setStep(step - 1);
            return;
        }

        props.dismiss();
        setStep(1);
    }

    return (
        <div id="review">
            {step === 1 &&
                <div>
                    <div className="reviewTitle">
                        {I18n.t('review:mainQuestion')}
                    </div>
                    {[...Array(5)].map((star, index) => {
                        index += 1;
                        return (
                            <button
                                type="button"
                                key={index}
                                className={(index <= (hover || rating) ? "starButtonOn" : "starButtonOff") + " starButton"}
                                onClick={() => setRating(index)}
                                onMouseEnter={() => setHover(index)}
                                onMouseLeave={() => setHover(rating)}
                            >
                                <span className="star">&#9733;</span>
                            </button>
                        );
                    })}
                    <div className="starFooter">
                        <div>{I18n.t('review:badQuality')}</div>
                        <div>{I18n.t('review:goodQuality')}</div>
                    </div>
                    {/*<GoogleReviewsButton/>*/}
                </div>
            }

            {step === 2 && rating > 0 && rating < 5 && props.issues
                && <RadioButtons
                    type={'radio'}
                    name={'network'}
                    title={I18n.t('review:networkQuestion')}
                    otherTitle={I18n.t('review:networkQuestion')}
                    issues={['MOBILE', 'WIFI']}
                    description={audioIssueDescription}
                    onChangeDescription={setAudioIssueDescription}
                    onSelect={(value) => {
                        setNetworkType(value)
                    }}
                />
            }

            {step === 3 && rating > 0 && rating < 5 && props.issues
                && <RadioButtons
                    type={'checkbox'}
                    name={'audioIssue'}
                    title={I18n.t('review:audioQuestion')}
                    otherTitle={I18n.t('review:audioQuestion')}
                    issues={props.issues.audio}
                    description={audioIssueDescription}
                    onChangeDescription={setAudioIssueDescription}
                    onSelect={(value) => {
                        setAudioIssues(prepareArray(audioIssues, value))
                    }}
                />
            }

            {step === 4 && rating > 0 && rating < 5 && props.issues
                && <RadioButtons
                    type={'checkbox'}
                    name={'videoIssue'}
                    title={I18n.t('review:videoQuestion')}
                    otherTitle={I18n.t('review:audioQuestion')}
                    issues={props.issues.video}
                    description={videoIssueDescription}
                    onChangeDescription={setVideoIssueDescription}
                    onSelect={(value) => {
                        setVideoIssues(prepareArray(videoIssues, value))
                    }}
                />}

            {step !== 5 &&
                <div className={'reviewFooter'}>
                    <button onClick={onCancel} className={'reviewDismiss'}>
                        {step === 1 ? I18n.t('review:cancel') : I18n.t('review:back')}
                        {}
                    </button>
                  <button onClick={onSubmit} className={'reviewSubmit' + (rating === 0 ? ' reviewSubmitDisabled' : '')} disabled={rating === 0}>
                      {step === 4 || (step === 1 && rating > 4) ? I18n.t('review:submit') : I18n.t('review:next')}
                  </button>
                </div>
            }

            {step === 5 && <div>
                <button onClick={onCancel} className={'reviewTimesDismiss'}>x</button>
                <h4>{I18n.t('review:successTitle')}</h4>
                {I18n.t('review:successDesc')}
            </div>}
        </div>
    );
}

