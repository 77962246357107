import React, {Component} from 'react';
import {IProps, IState} from "./types";
import I18n from "i18next";
import {withTranslation} from "react-i18next";


class VideoTest extends Component<IProps, IState> {

    private testCanvas: any;
    private testVideo: any;

    constructor(props: any) {
        super(props);

        this.state = {
            stream: this.props.stream,
            minMicVolume: false,
            availableCam: true,
            availableMic: true,
        }
    }

    componentDidMount(): void {
        this.testCanvas = this.refs.testCanvas;
        this.testVideo = this.refs.testVideo;
       if (this.state.stream && !this.state.stream.getVideoTracks().length) {
           this.setState({
               availableCam: false
           })
       }

        if (this.state.stream &&  !this.state.stream.getAudioTracks().length) {
            this.setState({
               availableMic: false
           })
        }

        if (this.state.stream && this.state.availableCam && this.testVideo) {
            this.renderVideo()
        }

        if (this.state.stream && this.state.availableMic) {
            this.renderIndicator(() => {
                this.setState({minMicVolume: true});
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.stream !== this.props.stream) {
            this.setState({
                stream: this.props.stream
            }, () => {
                this.renderVideo()
                if (!prevState.stream && this.props.stream) {
                    if (this.props.stream && !this.props.stream.getVideoTracks().length) {
                        this.setState({
                            availableCam: false
                        })
                    }

                    if (this.props.stream && !this.props.stream.getAudioTracks().length) {
                        this.setState({
                            availableMic: false
                        })
                    }

                    if (this.props.stream && this.state.availableMic) {
                        this.renderIndicator(() => {
                            this.setState({minMicVolume: true});
                        })
                    }
                }
            })
        }

    }

    renderVideo = () => {
        if (this.state.stream === null) return

        this.testVideo.srcObject = this.state.stream;
        this.testVideo.volume = 0.2;
    };

    renderIndicator = (callback:any) => {
        // @ts-ignore
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        const audioContext = new AudioContext();
        const microphone = audioContext.createMediaStreamSource(this.state.stream);
        const javascriptNode = audioContext.createScriptProcessor(1024, 1, 1);
        microphone.connect(javascriptNode);
        javascriptNode.connect(audioContext.destination);
        javascriptNode.onaudioprocess = function (event) {
            const inpt_L = event.inputBuffer.getChannelData(0);
            let instant_L = 0.0;
            let sum_L = 0.0;
            let max_level_L = 0

            for (var i = 0; i < inpt_L.length; ++i) {
                sum_L += inpt_L[i] * inpt_L[i];
            }

            instant_L = Math.sqrt(sum_L / inpt_L.length);
            max_level_L = Math.max(max_level_L, instant_L);

            if (max_level_L > 0.16) {
                callback()
            }
        }
    }

    render () {
        return (<div className="settings-test">
            <div className={!this.state.availableCam ? 'settings-test-video-noCam settings-test-video' : 'settings-test-video'}>
                {this.state.availableCam ?
                    <video ref='testVideo' playsInline
                           id="testVideo"
                           autoPlay controls={false}
                    />
                    : <div>{I18n.t('settings:noCam')}</div>
                }
                <canvas ref='testCanvas' id="client-canvas"/>
            </div>

            <div className="settings-test-volume-control">
                <p className={this.state.minMicVolume ? 'success' : (!this.state.availableMic ? 'error' : '')}>&nbsp;</p>
                <span className={this.state.minMicVolume ? 'success' : (!this.state.availableMic ? 'error' : '')}>
                    <i>
                        {this.state.availableMic ? (
                            this.state.minMicVolume ? I18n.t('settings:micTestSuccess') : I18n.t('settings:micTest')
                        ) : I18n.t('settings:noMic')}
                        </i>
                </span>
            </div>
        </div>)
    }
}

export default withTranslation()(VideoTest)
