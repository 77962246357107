import React, {useEffect, useState} from 'react'
import {IProps} from "./types";
import I18n from "i18next";
import {connect} from "react-redux";
import {REDUCER_TYPE} from "../../modules/Reducer";
import {useTranslation} from "react-i18next";

const AuthUser = (props: IProps) => {
    // @ts-ignore
    useTranslation()
    const [authUser, setAuthUser] = useState(props.authUser)

    useEffect(function () {
        setAuthUser(props.authUser)
    }, [props])

    if (authUser) {
        return (
            <div className="logged_name">
                {I18n.t('app:logged')}: {authUser.name}
            </div>
        )
    }

    return null;
};

const mapStateToProps = (state: REDUCER_TYPE) => {
    return state
}

export default connect(mapStateToProps)(AuthUser)
