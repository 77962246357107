import React, {Component} from "react";
import {IProps, IState} from "./types";
import I18n from "i18next";
import startCall from "../../assets/img/startcall.svg"
import gear from "../../assets/img/gear.svg"
import VisitInfo from '../VisitInfo';
import {REDUCER_TYPE} from "../../modules/Reducer";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {CallReview} from "../CallReview";


class BeforeCall extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.renderHref = this.renderHref.bind(this);

        this.state = {
            isCall: props.isCall,
            isAttemptingCall: props.isAttemptingCall,
            isReconnecting: props.isReconnecting,
            needReview: false
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (this.props.isAttemptingCall !== this.state.isAttemptingCall || this.props.isCall !== this.state.isCall || this.props.isReconnecting !== this.state.isReconnecting)  {
            const needReview = this.state.isCall === true && this.props.isCall === false
            this.setState({
                isAttemptingCall: this.props.isAttemptingCall,
                isCall: this.props.isCall,
                isReconnecting: this.props.isReconnecting,
                needReview: needReview
            })
        }

        if (this.props.room !== this.state.room) {
            this.setState({room: this.props.room})
        }

        if (this.props.remoteUser !== this.state.remoteUser) {
            this.setState({remoteUser: this.props.remoteUser})
        }

        if (this.props.loggedData !== this.state.loggedData) {
            this.setState({loggedData: this.props.loggedData})
        }

        if (this.props.room !== this.state.room) {
            this.setState({room: this.props.room})
        }
    }

    render() {
        return (
            <>
                <div
                    className="video-before"
                    style={(this.state.isCall && !this.state.isReconnecting ? {display: "none"} : {})}
                >
                    {this.state.needReview &&
                        <CallReview
                            callId={this.props.callId}
                            token={this.props.jwtToken ? this.props.jwtToken : ''}
                            room={this.props.room?.uuid ? this.props.room?.uuid : ''}
                            participant={this.props.authUser?.uuid ? this.props.authUser.uuid : ''}
                            issues={this.props.reviewIssues}
                            dismiss={() => {
                                this.setState({needReview: false})
                            }}
                        />
                    }

                    {!this.state.needReview &&
                        <div>
                            <div className="video-before-conf">
                                 <span
                                     className="link video-before-control-configuration"
                                     onClick={() => {
                                         localStorage.removeItem('camId');
                                         localStorage.removeItem('micId')
                                         localStorage.removeItem('configured')
                                         window.onbeforeunload = function (e: any) {
                                             return null;
                                         };
                                         window.location.reload();
                                     }}
                                 >
                                    <img className="cog-" src={gear} alt="Konfiguracja"/>
                                </span>
                            </div>


                            {this.state.room && !this.state.room.closed &&
                                <VisitInfo
                                    room={this.state.room}
                                    remoteUser={this.state.remoteUser}
                                    remoteUserActive={this.state.loggedData?.remoteUserActive}
                                />
                            }

                            <div className="video-before-icon">
                                {this.state.remoteUser && this.state.remoteUser.photoUrl ?
                                    <img src={this.state.remoteUser.photoUrl} className="video-before-icon" style={{objectFit: "cover"}}/>
                                    : this.getFirstLetters(this.state.remoteUser ? this.state.remoteUser.name : null)
                                }
                            </div>

                            <div className="video-before-label text-center">
                                {this.state.isReconnecting && <p className="text-center">{I18n.t('video:tryingToReconnect')}</p>}
                                {this.state.remoteUser && this.state.remoteUser.name}
                                {(this.state.isAttemptingCall || this.state.isReconnecting) &&
                                    <div id="wave">
                                        <span className="dot">&nbsp;</span>
                                        <span className="dot">&nbsp;</span>
                                        <span className="dot">&nbsp;</span>
                                    </div>
                                }
                            </div>

                            <div className="video-before-control">
                                {this.renderHref(window.location.href)}
                            </div>
                        </div>
                    }
                </div>
            </>
        );
    }

    renderHref(href: string) {
        if (this.roomIsDisabled()) {
            return (<span className="link" onClick={this.props.onCall}>
                <img
                    className="messages-bar-uploadBtn-disabled"
                    src={startCall} alt=""
                />
            </span>)
        }

        if (this.state.isAttemptingCall || this.state.isCall || this.state.isReconnecting)
            return <p>{I18n.t('beforeVideo:connecting')}...</p>;

        if (this.state.loggedData) {
            if (!this.state.loggedData?.remoteUserActive)
                return <p>{this.state.remoteUser?.name} {I18n.t('beforeVideo:awayStatus')}</p>;

            if (!this.state.loggedData.authUserConf)
                return (
                    <span className="link" onClick={() => {
                        window.onbeforeunload = function (e: any) {
                            return null;
                        };
                        window.location.href = href;
                    }}>{I18n.t('beforeVideo:notAllowedPermissions')}</span>
                )

            if (!this.state.loggedData.remoteUserConf)
                return <p>{this.state.remoteUser?.name} {I18n.t('beforeVideo:remoteUserNotAllowed')}</p>

            if (
                this.state.loggedData.authUserConf
                && this.state.loggedData.remoteUserConf
                && this.state.loggedData.remoteUserActive
            )
                return (
                    <span className="link" onClick={this.props.onCall}><img src={startCall} alt=""/></span>
                )
        }
    }

    getFirstLetters(name: string | null) {
        if (!name)
            return (<p/>)

        let split = name.split(" ");

        let shortName = '';

        if (split[0])
            shortName += split[0].charAt(0);


        if (split[1])
            shortName += split[1].charAt(0);


        return (<p>{shortName}</p>)
    }

    roomIsDisabled() {
        return this.state.room && this.state.room.closed;
    }
}

const mapStateToProps = (state: REDUCER_TYPE) => {
    return state
}
// @ts-ignore
export default withTranslation()(connect(mapStateToProps)(BeforeCall));
