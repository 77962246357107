import React from 'react'
import {IProps} from "./types";

const getFirstLetters = (name: string | null) => {
    if (!name)
        return (<p/>)

    let split = name.split(" ");

    let shortName = '';

    if (split[0]) {
        shortName += split[0].charAt(0);
    }

    if (split[1]) {
        shortName += split[1].charAt(0);
    }

    return (<p>{shortName}</p>)
}

export const CallPlaceholder = (props: IProps) => {
    return (
        <div className="video-before">
            <div className="video-before-icon">
                {getFirstLetters(props.remoteUser ? props.remoteUser.name : null)}
            </div>
            <div className="video-before-label">
                {props.remoteUser && props.remoteUser.name}
            </div>
        </div>
    )
}

