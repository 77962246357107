import React from "react";
import {IProps} from "./types";
import {ModuleValues} from "../../types"
import logo from "../../assets/img/logo.svg";
import video from "../../assets/img/menu-video.svg";
import videoActive from "../../assets/img/menu-video-active.svg";
import chat from "../../assets/img/chat-icon.svg";
import newMessageChat from "../../assets/img/new-message-icon.svg";
import chatActive from "../../assets/img/menu-chat-active.svg";
import documents from "../../assets/img/doc-icon.svg";
import documentsActive from "../../assets/img/menu-doc-active.svg";
import qrIcon from "../../assets/img/menu_qr_inactive.svg";
import qrIconActive from "../../assets/img/menu_qr_active_left.svg";
import help from "../../assets/img/menu-help-icon.svg"
import I18n from "i18next";
import {useTranslation} from "react-i18next";

const SidebarList = (props: IProps) => {
    useTranslation()
    return (
        <>
            <li className="logo" onClick={() => props.changeModule(ModuleValues.VIDEO)}>
                <img className="logo" src={logo} alt=""/>
            </li>
            <li onClick={() => props.changeModule(ModuleValues.VIDEO)}>
                <img src={props.chosenModule === ModuleValues.VIDEO ? videoActive : video} alt=""/><br/>
                <span>{I18n.t('sidebar:video')}</span>
            </li>
            <li onClick={() => props.changeModule(ModuleValues.CHAT)}>
                <img src={props.chosenModule === ModuleValues.CHAT ? chatActive : (props.isNewMessage ? newMessageChat : chat)} alt=""/><br/>
                <span>{I18n.t('sidebar:chat')}</span>
            </li>
            <li onClick={() => props.changeModule(ModuleValues.DOC)}>
                <img src={props.chosenModule === ModuleValues.DOC ? documentsActive : documents} alt=""/><br/>
                <span>{I18n.t('sidebar:doc')}</span>
            </li>

            <li onClick={() => props.changeModule(ModuleValues.QR)} className="qrcode">
                <img src={props.chosenModule === ModuleValues.QR ? qrIconActive : qrIcon} alt=""/><br/>
                <span>{I18n.t('sidebar:qr')}</span>
            </li>

            <li onClick={() => {props.changeModule(ModuleValues.HELP)}}>
                <img src={help} alt=""/><br/>
                <span>{I18n.t('sidebar:help')}</span>
            </li>
        </>
    );
};

export default SidebarList
