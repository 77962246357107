import React from "react";
import {IProps} from "./types";
import I18n from "i18next";
import moment from "moment";
import calculateDate from "../../utils/date/date";
import {useTranslation} from "react-i18next";

const VisitInfo = (props: IProps) => {
    useTranslation()
    const { room, remoteUser, remoteUserActive } = props;

    if (!room?.startAt && remoteUser && remoteUser.role === 'Admin' && !remoteUserActive) {
        return <div className="video-before-info">
            {I18n.t('beforeVideo:waitForDoctor')}
        </div>;
    }

    if (room) {
        // @ts-ignore

        let startDate = null;
        let startTime = null
        if (room.startAt) {
            const startAt = moment(calculateDate(room.startAt));
            startDate = startAt.format('YYYY-MM-DD')
            startTime = startAt.format('HH:mm')
        }

        return <div className="video-before-info">
            {startTime && startDate &&
                <>
                    {I18n.t('beforeVideo:visitStartAtDay')}<br/>
                    {startDate === moment(calculateDate(null)).format('YYYY-MM-DD') ? I18n.t('beforeVideo:today') : startDate}{' '}
                    {I18n.t('beforeVideo:visitStartAtTime')}{' '}{startTime}
                </>
            }
            {remoteUser && remoteUser.role === 'Admin' && !remoteUserActive &&
                <>
                   <br/>
                    {I18n.t('beforeVideo:waitForDoctor')}
                </>
            }
        </div>
    }

    return null;
}

export default VisitInfo
