export const getParticipant = (me: any, participants: any) => {
    let participant = null;
    participants.forEach((item: any) => {
        if (item.uuid !== me.uuid) {
            participant = item;
        }
    });
    return participant;
};

export const getSpecialist = (participants: any) => {
    let specialist = null;
    participants.forEach((item: any) => {
        if (item.role === 'admin') {
            specialist = item;
        }
    });
    return specialist;
};