import React, {useState, useEffect} from "react";
import I18n from "i18next";
import {IProps} from "./types"
import {Attachment} from '../../components/Attachment'
import {REDUCER_TYPE} from "../../modules/Reducer";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

const AttachmentList = (props: IProps) => {
    useTranslation()
    const [items, setItems] = useState([])

    useEffect(() => {
        // @ts-ignore
        setItems(props.attachments)
    }, [props])

    return (
        <div
            className="attachments"
            style={(props.isActiveTab ? {} : {display: "none"})}
        >
            <div className="attachments-container">
                <p className="attachments-title">
                    {I18n.t('attachments:title')}
                </p>
                <div className="attachments-list-container" style={{height: "100%", overflow: "hidden"}}>
                    <ul className="list-group attachments-list">
                        {items && items.map((item: any, key: number) => {
                            return <Attachment item={item} key={key}/>
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = (state: REDUCER_TYPE) => {
    return state
}

// @ts-ignore
export default connect(mapStateToProps)(AttachmentList);

