import React from 'react';
import {IProps} from './types';
import parse from 'html-react-parser';
import moment from "moment";
import calculateDate from "../../utils/date/date";

export const Attachment = (props: IProps) => {
    if (!props.item) {
        return null;
    }

    return (
        <li key={props.item.uuid}>
            <p>{parse(props.item.content)}</p>
            <span>
                {props.item.participant.name}&nbsp;
                {moment(calculateDate(props.item.createdAt)).format('YYYY-MM-DD HH:mm')}
            </span>
        </li>
    );
};
