export interface Room {
    uuid: string;
    title?: string | null;
    description?: string | null;
    photo?: string | null;
    createdAt?: string;
    expireAt?: string | null;
    welcome?: string|null;
    startAt?: string;
    duration?: number
    closed?: boolean
    janus?: string|null
}


export interface  Participant {
    uuid: string;
    name: string;
    role: string;
    photoUrl?: string;
}

export interface Message {
    uuid?: string|null;
    content: string | null;
    attachment?: Attachment | any;
    createdAt: string;
    participant?: Participant | null;
}

export interface Attachment {
    id?: number;
    uuid?: string;
    filename?: string;
    title?: string;
    content: string;
    participant: Participant;
    createdAt: string;
};

export interface LoggedData {
    authUserConf: boolean;
    authMicId: string,
    authCamId: string,
    remoteUserConf: boolean;
    remoteUserActive: boolean;
}

export enum ModuleValues {
    VISIT = 'visit',
    VIDEO = 'video',
    CHAT = 'chat',
    DOC = 'doc',
    QR = 'qr',
    HELP = 'help'
}
