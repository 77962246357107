import React, {Dispatch, SetStateAction, useState} from "react";
import {useTranslation} from "react-i18next";
import I18n from "i18next";
export const RadioButtons = (props: {
    title: string;
    type: string|null;
    name: string;
    otherTitle: string;
    issues: string[],
    description: string,
    onChangeDescription: Dispatch<SetStateAction<string>>
    onSelect: (value: string) => void
}) => {
    useTranslation()

    const [other, setOther] = useState(false);

    const handleChange = (value:string) => {
        props.onChangeDescription(value);
    }

    if (!props.issues || !props.issues.length) {
        return null;
    }

    return <div className={"additional"}>
        <div className={"additionalTitle"}>{props.title}</div>
        {props.issues.map((value, index) => {
            return <div key={index} className={"radioButton"}>
                <label>
                    <input
                        type={props.type ? props.type : 'checkbox'}
                        name="props.name"
                        value={value}
                        onChange={() => {
                            props.onSelect(value)
                            if (value === 'AUDIO_OTHER' || value === 'VIDEO_OTHER') {
                                const showOther = !other;
                                if (!showOther) {
                                    handleChange("")
                                }
                                setOther(showOther);
                            }
                        }}
                    />
                    {I18n.t('review:'+value)}
                </label>
            </div>
        })}

        {other &&
            <div className={"otherIssues"}>
                <label>{props.otherTitle}</label>
                <div>
                    <textarea
                        onChange={(event) => {handleChange(event.target.value)}}
                    >{props.description}</textarea>
                </div>
            </div>
        }
    </div>
}