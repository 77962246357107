import { Manager } from "socket.io-client";

const manager = new Manager(process.env.REACT_APP_WSS_ENDPOINT);
const socket = manager.socket("/");

manager.open((err) => {
    if (err) {
        console.log('Manager open error')
    } else {
        console.log('Manager open success!')
    }
});

socket.io.on('error', (error) => {
    console.log('Manager error')
});

socket.io.on("ping", () => {
    console.log('Manager ping')
});

socket.io.on("reconnect", (attempt) => {
    const reconnectEvent = new CustomEvent('socket.reconnect');
    window.dispatchEvent(reconnectEvent);
});

socket.io.on("reconnect_attempt", (attempt) => {
    console.log('Manager reconnect_attempt', 'Attempt:' + +attempt)
});

socket.io.on("reconnect_error", (error) => {
    console.log('Manager reconnect_error')
});

socket.io.on("reconnect_failed", () => {
    console.log('Manager reconnect_failed')
});

 export const io = socket;
