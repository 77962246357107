import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './utils/translation';
import { Provider } from 'react-redux'
// import {sendError} from "./utils/logs";
import configureStore from './modules/store'


// sendError();

function getParameterByName(name: string, url: string) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const store = configureStore()

const AppRoot = (
    <Provider store={store}>
        <App
            token={String(getParameterByName('t', window.location.href)
                ? getParameterByName('t', window.location.href)
                : String(
                    process.env.NODE_ENV === 'development' && process.env.REACT_APP_DEV_TOKEN !== 'undefined'
                        ? process.env.REACT_APP_DEV_TOKEN
                        : ''))
            }
        />
</Provider>
)

ReactDOM.render(AppRoot, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
