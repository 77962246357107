import React, { useState } from "react";
import { IProps } from "./types";
import fullScreen from "../../assets/img/fullscreen-icon.svg";
import fullScreenOff from "../../assets/img/fullscreen-off-icon.svg";
import pinp from "../../assets/img/pictureInpicture-icon.svg";
import pinpOff from "../../assets/img/pictureinpicture-off-icon.svg";
import videoOff from "../../assets/img/video-off.svg";
import videoOn from "../../assets/img/video-icon.svg";
import cameraArrowOff from "../../assets/img/camera-arrow-off.svg";
import cameraArrowOn from "../../assets/img/camera-arrow-on.svg";
import voiceOn from "../../assets/img/voice-on.svg";
import voiceOff from "../../assets/img/voice-off.svg";
import endCall from "../../assets/img/endcall.svg";
import toggleCamera from "../../assets/img/toggle-camera.svg";
import arrowDownFa from "../../assets/img/arrow-down-fa.svg";
import arrowDownFaColor from "../../assets/img/arrow-down-fa-color.svg";
import cameraFa from "../../assets/img/camera-fa.svg";
import checkFa from "../../assets/img/check-fa.svg";

const CallControl = (props: IProps) => {
    const [showToggleCamera, setShowToggleCamera] = useState(false);
    const [showCameras, setShowCameras] = useState(false);

    return (
        <div style={{zIndex: 1}}>
            {showToggleCamera && <>
                {showCameras && <>
                    <div className="cameras-dialog-container">
                        {props.cameras.map((camera) => (
                            <div
                                key={camera.deviceId}
                                style={{color: props.currentUsedCamera!.label !== camera.label ? "#FFF" : ""}}
                                className="cameras-dialog-wrapper"
                                onClick={(e) => props.changeCamera(e, camera)}
                            >
                                {props.currentUsedCamera!.label === camera.label && <img src={checkFa} alt=""/>}
                                <div className="camera-label">
                                    {camera.label}
                                </div>
                            </div>
                        ))}
                    </div>
                </>}

              <div className="cameras-toggle-container">
                  <div className="cameras-toggle-content-wrapper">
                      <img src={cameraFa} alt=""/>
                      <div className="camera-label">{props.currentUsedCamera!.label}</div>
                  </div>
                  <div className="cameras-toggle-arrow-wrapper">
                      <img onClick={() => setShowCameras(prev => !prev)} src={showCameras ? arrowDownFa : arrowDownFaColor} alt=""/>
                  </div>
              </div>
            </>}


            <div className="video-controls video-controls-classic">
                <div className="voice-container" onClick={(!props.disableMute ? props.toggleStreamVoice : console.log('you dont have a mic'))}>
                    <img src={props?.isMute ? voiceOff : voiceOn} alt=""/>
                </div>

                <div onClick={props.endVideo} className="video-controls-classic-endcall">
                    <img src={endCall} alt=""/>
                </div>

                <div className="camera-switch-container">
                    <img
                        onClick={(!props.disableBlank ? props.toggleStreamVideo : console.log('you dont have a cam'))}
                        className="camera-icon"
                        src={props?.isBlank ? videoOff : videoOn}
                        alt=""
                    />
                    <img onClick={() => {
                        setShowCameras(false);
                        setShowToggleCamera(prev => !prev);
                    }} src={showToggleCamera ? cameraArrowOff : cameraArrowOn} alt="" className="camera-switch-background"/>
                </div>
            </div>

            {!props.remoteUserNoCam &&
                <>
                    <div className="video-controls video-controls-resize">
                        <div onClick={props.pictureInPictureVideo}>
                            <img src={props?.isPictureInPicture ? pinpOff : pinp} alt=""/>
                        </div>

                        <div
                        onClick={props.fullScreenVideo}
                        >
                            <img src={props?.isFullScreen ? fullScreenOff : fullScreen} alt=""/>
                        </div>
                    </div>

                    <div
                      onClick={(e) => props.toggleCamera(e)}
                      className="toggle-camera"
                    >
                        <img src={toggleCamera} alt=""/>
                    </div>
                </>
            }
        </div>
    )
};

export default CallControl;