import axios from 'axios';
const uploadUrl = process.env.REACT_APP_MEDCONNECT + '/room/{roomUuid}/attachments/{participantUuid}';

const FetchUpload = (token: string, room: any | null, participant: any | null | undefined, data: any, progressBar:any, onFail: any) => {
    let url = uploadUrl;
    url = url.replace('{roomUuid}', room.uuid);
    url = url.replace('{participantUuid}', participant.uuid);

    axios.request({
        method:"post",
        url: url + "?bearer=" + token,
        data: data,
        onUploadProgress: (progress) => {
          progressBar(progress.loaded, progress.total)
        },
    }).then(() => {

    }).catch((error: any) => {
        onFail(error)
    })
};

export default FetchUpload;
