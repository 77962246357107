import React, {Component} from 'react';
import I18n from "i18next";
import {IProps, IState} from "./types";
import {sendNotAnswerCall, sendVideoCall} from "../../utils/api/send";
import BeforeCall from "../../components/BeforeCall";
import {confirmAlert} from 'react-confirm-alert';
import Video from "../Video";
import VideoController from "../../utils/janus/controller";
import {REDUCER_TYPE} from "../../modules/Reducer";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Bitrate from "../../components/Bitrate";
import ErrorIcon from "../../components/ErrorIcon";


class VideoContainer extends Component <IProps, IState> {
    protected VideoController: any = null;
    protected callTimeout: any;

    constructor(props: IProps) {
        super(props);
        this.state = {
            isActiveTab: props.isActiveTab,
            isCall: false,
            isAttemptingCall: false,
            isReconnecting: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (this.state.isActiveTab !== this.props.isActiveTab) {
            this.setState({isActiveTab: this.props.isActiveTab})
        }

        if (this.state.loggedData !== this.props.loggedData) {
            this.setState({
                loggedData: this.props.loggedData
            }, () => {
                if (VideoController) this.VideoController.updateLoggedData(this.state.loggedData)
            });
        }

        if (!this.VideoController
            && (this.props.authUser !== prevProps.authUser
                || this.props.remoteUser !== prevProps.remoteUser
                || this.props.loggedData !== prevProps.loggedData
                || this.props.room !== prevProps.room
                || this.props.socketId !== prevProps.socketId
            )
        ) {
            this.VideoController = new VideoController(
                this.props.authUser,
                this.props.remoteUser,
                this.props.loggedData,
                this.props.room,
                this.props.socketId
            );
        }
    }

    setCallState = (isCall: boolean, isAttemptingCall: boolean) => {
        if (!isCall && !isAttemptingCall && this.callTimeout) {
            clearTimeout(this.callTimeout)
        }
        this.setState({
            isCall: isCall,
            isAttemptingCall: isAttemptingCall
        })
    }

    setReconnect = (isReconnect: boolean) => {
        this.setState({isReconnecting: isReconnect});
    }

    onCall = (event: any) => {
        event.preventDefault();

        this.setState({
            isAttemptingCall: true,
        });

        sendVideoCall();

        this.callTimeout = setTimeout(() => {
            if (!this.state.isCall && this.state.isAttemptingCall) {
                sendNotAnswerCall();
                confirmAlert({
                    customUI: ({onClose}) => {
                        setTimeout(() => {
                            let alert = document.getElementById('not-answer-modal');
                            if (alert) {
                                onClose();
                                this.setState({isAttemptingCall: false});
                            }
                        }, 3000)
                        return (<div id="not-answer-modal" className='custom-alert custom-alert-text-center'>
                            <p>
                                <b>{this.props.remoteUser?.name}</b><br/>
                                {I18n.t('video:notAnswer')}
                            </p>
                            <div>
                                <button className="btn btn-secondary" onClick={() => {
                                    this.setState({isAttemptingCall: false})
                                    onClose();
                                }}>{I18n.t('video:close')}</button>
                            </div>
                        </div>);
                    }
                });
            }
            return false
        }, 15000);
    };

    render() {
        return (<div className="video" style={(this.state.isActiveTab ? {} : {display: "none"})}>
            <Video
                setReconnect={this.setReconnect}
                setCallState={this.setCallState}
                VideoController={this.VideoController}
            />
            <BeforeCall
                onCall={this.onCall}
                isAttemptingCall={this.state.isAttemptingCall}
                isCall={this.state.isCall}
                isReconnecting={this.state.isReconnecting}
            />
            <Bitrate/>
            <ErrorIcon isCall={this.state.isCall}/>
        </div>)
    }
}


const mapStateToProps = (state: REDUCER_TYPE) => {
    return state
}
// @ts-ignore
export default withTranslation()(connect(mapStateToProps)(VideoContainer));
