import React from "react";
import {IProps} from "./types";
import moment from "moment";
import parse from 'html-react-parser';
import calculateDate from "../../utils/date/date";

const MessageItem = (props: IProps) => {
    const {message} = props;
    const {participant} = message;
    return (<>
        {participant?.uuid === props.myUuid ?
            renderAuthItem(message, participant) :
            renderRemoteItem(message, participant)
        }
    </>);
};

const timestampToDatetime = (timestamp: string) => {
    const messageDatetime = moment(calculateDate(timestamp));

    if (messageDatetime > moment(calculateDate(null))) {
        messageDatetime.subtract(2, 'hour')
    }

    let data = messageDatetime.format('YYYY-MM-DD');
    let today = moment(calculateDate(null))

    if (today.isSame(data, "day")) {
        return messageDatetime.format('HH:mm')
    } else {
        return messageDatetime.format('YYYY-MM-DD HH:mm')
    }
}

const getFirstLetters = (name: string | null) => {
    if (!name) return (<p></p>)

    let splitted = name.split(" ");

    let shortName = '';

    if (splitted[0]) {
        shortName += splitted[0].charAt(0);
    }

    if (splitted[1]) {
        shortName += splitted[1].charAt(0);
    }
    return (<p>{shortName}</p>)
}

const renderRemoteItem = (message: any, remoteUser: any) => {
    return (<>
        <div className="messages-list-item" key={message.uuid}>
            <div className="messages-list-item-container">
                <div className="messages-list-item-icon mr-3">
                    {getFirstLetters(remoteUser ? remoteUser.name : null)}
                </div>
                <div className="messages-list-item-container-body">
                    <p className="mt-0 messages-list-item-label">
                        <strong>
                            {remoteUser ? remoteUser.name : ''}
                        </strong>
                        <span className="text-muted mr-3 ml-3">
                            {timestampToDatetime(message.createdAt)}
                        </span>
                    </p>
                    <div className="messages-list-item-content">
                        {parse(message.content || "")}
                    </div>
                </div>
            </div>
        </div>
    </>);
};

const renderAuthItem = (message: any, authUser: any) => {
    return (
        <div className="messages-reverse">
            <div className="messages-list-item messages-list-item-my">
                <div className="messages-list-item-container">
                    <div className="messages-list-item-container-body">
                        <p className="mt-0  messages-list-item-label messages-list-item-label-my">
                            <span className="text-muted mr-3 ml-3">
                                {timestampToDatetime(message.createdAt)}
                            </span>
                            <strong>
                                {authUser ? authUser.name : ''}
                            </strong>
                        </p>
                        <div className="messages-list-item-content messages-list-item-content-my">
                            {parse(message.content || "")}
                        </div>
                    </div>
                    <div className="messages-list-item-icon messages-list-item-icon-my  ml-3">
                        {getFirstLetters(authUser ? authUser.name : null)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessageItem;
