import React, {Component} from 'react'
import {IProps, IState} from "./types";
import I18n from "i18next";
import VideoTest from "../VideoTest";
import Media from "../../utils/video/media";
import {withTranslation} from "react-i18next";

class Settings extends Component<IProps, IState> {

    protected audioInputSelect: any;
    protected videoInputSelect: any;
    protected selectors: any = [];
    protected Media: any;
    protected _isMounted: boolean = false;

    constructor(props: IProps) {
        super(props);

        this.state = {
            micPermissions: false,
            camPermissions: false,
            setStart: props.setStart,
            setMicId: props.setMicId,
            setCamId: props.setCamId,
            isCamera: false,
            isMic: false,
            navigatorError: false,
            navigatorPermissions: true,
            notSupported: false,
            stream: false
        }
        this._isMounted = true;

        this.audioInputSelect = React.createRef();
        this.videoInputSelect = React.createRef();
        this.selectors = [this.videoInputSelect, this.audioInputSelect]
        this.Media = new Media(navigator, {});
    }

    componentDidMount(): void {
        this.checkPermissions().then(r => {
            if (this.state.micPermissions && this.state.camPermissions) {
                if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
                    console.log("enumerateDevices() not supported.");
                    this.setState({
                        navigatorError: true
                    })
                } else {
                    navigator.mediaDevices.enumerateDevices().then(this.gotDevices).catch(this.handleError);
                }
            }
        });

        this.detectIeEdge();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    checkPermissions = async () => {
        return await this.Media.getStream().then((stream: any) => {
            stream.getVideoTracks()[0].stop()
            stream.getVideoTracks()[0].enabled = false
            stream.getAudioTracks()[0].stop()
            stream.getAudioTracks()[0].enabled = false
            this.setState({
                micPermissions: true,
                camPermissions: true
            })
        })
    };

    getPermissions = () => {
        if (!navigator.mediaDevices.getUserMedia) {
            this.setState({navigatorError: true});
            return;
        }

        if (this.state.stream) {
            this.state.stream.getVideoTracks()[0].stop()
            this.state.stream.getVideoTracks()[0].enabled = false
            this.state.stream.getAudioTracks()[0].stop()
            this.state.stream.getAudioTracks()[0].enabled = false
        }

        this.setState({stream: null});
        this.Media.clearStream()
        this.Media.getStream().then((stream: any) => {
            if (this._isMounted) {
                this.setState({stream: stream})
                this.setState({
                    micPermissions: true,
                    camPermissions: true
                })
            } else {
                const accessConfirm = new CustomEvent('access_confirm', {
                    detail: {
                        data: {micPermissions: true, camPermissions: true, stream: stream}
                    }
                })
                window.dispatchEvent(accessConfirm)
            }
        }).catch((response: any) => {
            if (this._isMounted) {
                this.setState({navigatorPermissions: false});
            } else {
                const accessConfirm = new CustomEvent('access_confirm', {
                    detail: {
                        data: {micPermissions: false, camPermissions: false}
                    }
                })
                window.dispatchEvent(accessConfirm)
            }
        });
    };

    gotDevices = (deviceInfos: any) => {
        this.selectors.forEach((select: any) => {
            let element = select.current;
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            element.value
        });

        this.selectors.forEach((select: any) => {
            let element = select.current;
            while (element.firstChild) {
                if (element.firstChild) {
                    element.removeChild(element.firstChild);
                }
            }
        });
        for (let i = 0; i !== deviceInfos.length; ++i) {
            const deviceInfo = deviceInfos[i];
            const option = document.createElement('option');
            option.value = deviceInfo.deviceId;
            if (deviceInfo.kind === 'audioinput') {
                option.text = deviceInfo.label || `microphone ${this.audioInputSelect.current.length + 1}`;
                this.audioInputSelect.current.appendChild(option);
            } else if (deviceInfo.kind === 'videoinput') {
                option.text = deviceInfo.label || `camera ${this.videoInputSelect.current.length + 1}`;
                this.videoInputSelect.current.appendChild(option);
            } else {
                console.log('Some other kind of source/device: ', deviceInfo);
            }
        }

        this.addEmptyOption('audio');
        this.addEmptyOption('video');

        this.setCamId(false);
        this.setMicId(true);
    };

    addEmptyOption = (type: string) => {
        let select;
        if (type === 'audio') {
            select = this.audioInputSelect.current;
        } else {
            select = this.videoInputSelect.current;
        }

        if (!select.children.length) {
            const option = document.createElement('option');
            option.value = '0';
            option.text = (type === 'audio'
                    ? I18n.t('settings:emptyAudio')
                    : I18n.t('settings:emptyVideo')
            );
            select.appendChild(option);
        }
    };

    handleError = (error: any) => {
        console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
    };

    setMicId = (getPermissions: boolean) => {
        let value = this.audioInputSelect.current.value;
        this.Media.setMicId(value)
        this.state.setMicId(value)
        this.setState({isMic: true})
        localStorage.setItem('micId', value);
        if (getPermissions) {
            this.getPermissions()
        }
    };

    setCamId = (getPermissions: boolean) => {
        let value = this.videoInputSelect.current.value;
        this.Media.setCamId(value)
        this.state.setCamId(value);
        this.setState({isCamera: true})
        localStorage.setItem('camId', value);
        if (getPermissions) {
            this.getPermissions()
        }
    };

    setStart = () => {
        if (this.state.stream) {
            this.state.stream.getTracks().forEach((track: any) => track.stop());
        }
        this.state.setStart(
            this.state.micPermissions,
            this.state.camPermissions
        );
    };

    detectIeEdge = () => {
        const ua = navigator.userAgent;
        const msie = ua.indexOf('MSIE ');
        const trident = ua.indexOf('Trident/');
        const edge = ua.indexOf('Edge/');
        let notSupported = false;

        if (msie > 0) {
            notSupported = true;
        }

        if (trident > 0) {
            notSupported = true
        }

        if (edge > 0) {
            notSupported = true;
        }
        this.setState({notSupported: notSupported})
    }


    render() {
        return (
            <div className="settings">

                <div className="settings-header">
                    <h2>{I18n.t('settings:config')}</h2>
                </div>

                {this.state.notSupported &&
                    <div className="settings-not-supported">
                        {I18n.t('settings:notSupported')}<br/>
                        {I18n.t('settings:whichSupports')}<br/>
                        <a href="https://www.mozilla.org/pl/firefox/new/">
                            <span>
                                <u>
                                    {I18n.t('settings:firefoxLink')}
                                </u>
                            </span>
                        </a>.
                    </div>
                }

                {this.state.micPermissions && this.state.camPermissions ? <>
                    <div className="settings-config">
                        <VideoTest
                            stream={this.state.stream}
                        />

                        <div className="settings-field">
                            <div className="settings-input">
                                <div className="settings-input-group">
                                    <label>{I18n.t('settings:microphone')}</label>
                                    <select
                                        ref={this.audioInputSelect}
                                        onChange={() => {this.setMicId(true)}}
                                        className="settings-input-group-control"
                                    >
                                        <option>{I18n.t('settings:selectDefault')}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="settings-input">
                                <div className="settings-input-group">
                                    <label>{I18n.t('settings:cam')}</label>
                                    <select
                                        ref={this.videoInputSelect}
                                        onChange={() => {this.setCamId(true)}}
                                        className="settings-input-group-control"
                                    >
                                        <option>{I18n.t('settings:selectDefault')}</option>
                                    </select>
                                </div>
                            </div>
                            {this.state.isCamera && this.state.isMic &&
                            <div className="text-center">
                                <button className="btn btn-success settings-btn" onClick={this.setStart}>
                                    {I18n.t('settings:runChat')}
                                </button>
                            </div>
                            }
                        </div>
                    </div>
                </> : <div className="settings-before-permissions">
                    <div>
                        {I18n.t('settings:useCamAndMic')}.<br/>
                        {I18n.t('settings:runWithoutCamAndMic')}.
                    </div>
                    <div className="text-center">
                        {!this.state.navigatorError && this.state.navigatorPermissions ?
                            <button className="btn btn-success settings-before-permissions-btn"
                                    onClick={() => {this.getPermissions()}}>
                                {I18n.t('settings:turnOn')}
                            </button> : <>
                                <button className="btn btn-secondary settings-before-permissions-btn"
                                        onClick={() => {
                                            window.onbeforeunload = function (e: any) {
                                                return null
                                            };
                                            window.location.reload()
                                        }}>
                                    {I18n.t('settings:failedTurnOn')}
                                </button>
                                <br/>
                                {this.state.navigatorPermissions ?
                                    <small className="text-danger">{I18n.t('settings:browserBlockPermissions')}</small>
                                    : <small className="text-danger">{I18n.t('settings:checkBrowserSettings')}</small>}
                            </>
                        }
                    </div>
                    <span className="link settings-before-permissions-link" onClick={this.setStart}>
                        {I18n.t('settings:runWithoutSettings')}
                    </span>

                </div>}
            </div>

        );
    }
}

export default withTranslation()(Settings)
