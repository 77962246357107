import React from "react";
import {IProps} from "./types";
import I18n from "i18next";
import {withTranslation} from "react-i18next";


const TypingPlaceholder = (props: IProps) => {
    const {isTyping, whoTyping} = props;
    return (
        <div className="messages-typing">
            {isTyping && renderTyping(whoTyping)}
        </div>
    );
}

const renderTyping = (remoteUser: any) => {
    if (remoteUser) {
        return (<>
            {remoteUser.name} {I18n.t('chatContainer:isTyping')}...
        </>);
    }
}

export default withTranslation()(TypingPlaceholder);
