import React from "react";
import {IProps} from "./types";
import I18n from "i18next";
import {useTranslation} from "react-i18next";

const Waiting = (props: IProps) => {
    useTranslation()
    if (!props.reconnecting) {
        return (<div className="waiting">
            {props.status >= 0 &&
                <h2>{I18n.t('waitingContainer:waiting')}</h2>
            }

            {props.status === -1 &&
                <h2>{I18n.t('waitingContainer:notAuthorized')}</h2>
            }

            {props.status === -2 &&
                (props.authUser && props.authUser.role === 'Admin' ?
                    <h2>{I18n.t('waitingContainer:expiredRoomDoctor')}</h2> :
                    <h2>{I18n.t('waitingContainer:expiredRoom')}</h2>
                )
            }
        </div>);
    } else {
        return (<div className="waiting">
            <div>
               <h2>
                   {I18n.t('waitingContainer:disconnected')}
               </h2>
                <br/>
                <h5>
                    {I18n.t('waitingContainer:reconnecting')}
                </h5>
            </div>
        </div>);
    }
};

export default Waiting;
