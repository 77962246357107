import { io } from "./index";

export const authorize = (token: string, sessionId: string|null) => {
    let data = {
        jwtToken: token,
        sessionId: sessionId
    };
    io.emit('authorize', data);
    return false;
};

export const sendMessage = (message: string) => {
    let data = {
        message: message
    };
    io.emit('chat.message', data);
    return false;
};

export const sendTyping = () => {
    let data = {};
    io.emit('chat.typing', data);
    return false;
};

export const sendVideoCall = () => {
    io.emit('video.call');
};

export const sendAnswer = (callerId: string) => {
    io.emit('video.answer', {
        callerId: callerId
    });
}

export const sendCallWith = (callWith: string|null) => {
    io.emit('video.callWith', {
        callWith: callWith
    });
}

export const sendError = (error: string) => {
    io.emit('janus.error', {
        error: error
    });
}

export const sendVideoStart = () => {
    let data = {};
    io.emit('video.start', data);
    return false;
};

export const sendVideoStop = () => {
    let data = {};
    io.emit('video.stop', data);
    return false;
};

export const sendVideoFullScreen = () => {
    let data = {};
    io.emit('video.fullscreen', data);
    return false;
};

export const sendVideoMimize = () => {
    let data = {};
    io.emit('video.minimize', data);
    return false;
};

export const sendMicMuted = () => {
    let data = {};
    io.emit('mic.muted', data);
    return false;
};

export const sendMicUnmute = () => {
    let data = {};
    io.emit('mic.unmuted', data);
    return false;
};

export const sendRejectSelfCall = (callerId: string) => {
    io.emit('video.disconnect', {
        reason: "3",
        callerId: callerId
    });
    return false;
};

export const sendRejectCall = () => {
    io.emit('video.disconnect', {
        reason: "1",
    });
    return false;
};

export const sendCloseVideo = () => {
    io.emit('video.disconnect', {
        reason: "2"
    });
    return false;
}

export const sendNotAnswerCall = () => {
    io.emit('video.disconnect', {
        reason: "4",
    });
    return false;
}

export const setRoomDuration = (duration:number) => {
    io.emit('chat.duration', {
        duration: duration
    });
}

export const setUserConfiguration = (
    participant_uuid: string,
    microphone: boolean,
    camera: boolean,
    configured: boolean,
) => {
    io.emit('chat.updateUserSettings', {
        participant_uuid: participant_uuid,
        microphone: microphone ? 1 : 0,
        camera: camera ? 1: 0,
        configured: configured ? 1 : 0,
    });
};

export const isSocketConnected = () => {
    return io.connected
}