import React from "react";
import I18n from "i18next";
import {IProps} from "./types";
import QRcode from "qrcode.react";
import {useTranslation} from "react-i18next";

const Share = (props: IProps)  => {
    useTranslation()
    return (<div className="attachments" style={(props.isActiveTab ? {} : {display: "none"})}>
        <div  className="attachments-container">
            <p className="attachments-title">
                {I18n.t('qr:title')}
            </p>
            <div className="attachments-list-container text-center">
                <div>
                    <span>{I18n.t('qr:description')}.</span>
                </div>
                <div style={{marginTop: '25px'}}>
                    <QRcode value={window.location.href} size={250} />
                </div>
            </div>
        </div>
    </div>)
};
export default Share;

