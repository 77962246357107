import React, {Component} from 'react';
import {IProps, IState} from "./types";
import VideoContainer from "../VideoContainer";
import MessageContainer from "../MessageContainer";
import Attachment from '../Attachments'
import StopWatch from "../../components/Stopwatch/index";
import {ModuleValues} from "../../types";
import Share from "../Share";
import AuthUser from '../../components/AuthUser';
import {REDUCER_TYPE} from "../../modules/Reducer";
import {connect} from "react-redux";

class Chat extends Component <IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            messages: props.messages,
            chosenModule: props.chosenModule,
            loggedData: props.state.loggedData
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.chosenModule !== this.props.chosenModule) {
            this.setState({chosenModule: this.props.chosenModule});
        }
        if (this.state.loggedData !== this.props.state.loggedData) {
            this.setState({loggedData: this.props.state.loggedData})
        }

        if (this.state.messages !== this.props.messages) {
            this.setState({
                messages: this.props.messages
            })
        }
    }

    getMessageContainerClass = () => {
        let messageContainerClass: string;
        if (this.state.chosenModule === 'video') {
            messageContainerClass = 'messages-video--width';
        } else if (this.state.chosenModule === 'chat') {
            messageContainerClass = 'messages-fullscreen';
        } else {
            messageContainerClass = 'messages-other--width';
        }
        return messageContainerClass;
    };

    render() {
        return (
            <div className="chat-container clear-space-sm">
                <AuthUser/>
                {/*<ChangeLanguage/>*/}
                <VideoContainer isActiveTab={this.state.chosenModule === ModuleValues.VIDEO}/>
                <Attachment isActiveTab={this.state.chosenModule === ModuleValues.DOC} />
                <Share isActiveTab={this.state.chosenModule === ModuleValues.QR}/>
                <MessageContainer
                    messages={this.state.messages}
                    chosenModule={this.state.chosenModule}
                    tabClass={this.getMessageContainerClass()}
                />
                <StopWatch/>
            </div>
        )
    }
}


const mapStateToProps = (state: REDUCER_TYPE) => {
    return {
        state: state
    }
}
// @ts-ignore
export default connect(mapStateToProps)(Chat)
