import {
    ATTACHMENTS_UPDATE,
    AUTHORIZATION_SUCCESS,
    IS_JANUS_CALL,
    IS_NODE_DISCONNECTED,
    LOGGED_DATA_UPDATE,
    MESSAGES_UPDATE,
    REVIEW_ISSUES,
    CALL_ID
} from "./Types";
import {Attachment, LoggedData, Message, Participant, Room} from "../types";

export interface REDUCER_TYPE {
    jwtToken: String;
    socketId: String;
    room: Room|null,
    authUser: Participant|null,
    remoteUser: Participant|null,
    messages: Message[]|null,
    attachments: Attachment[]|null,
    loggedData: LoggedData|any,
    isJanusCall: boolean,
    reviewIssues: { audio: string[], video: string[] } | null;
    callId: number
}

const initialState = {
    jwtToken: '',
    socketId: '',
    room: null,
    authUser: null,
    remoteUser: null,
    messages: null,
    attachments: null,
    loggedData: null,
    isJanusCall: false,
    isNodeDisconnected: false,
    reviewIssues: null,
    callId: 0,
}

export default (state = initialState, action: any) => {
    console.log('---' + action.type + '---', action.payload)
    switch (action.type) {
        case AUTHORIZATION_SUCCESS:
            return {
                ...state,
                jwtToken: action.payload.token,
                socketId: action.payload.socketId,
                room: action.payload.room,
                authUser: action.payload.authUser,
                remoteUser: action.payload.remoteUser,
                messages: action.payload.messages,
                attachments: action.payload.attachments,
            }
        case LOGGED_DATA_UPDATE:
            return {
                ...state,
                loggedData: action.payload
            }
        case IS_JANUS_CALL:
            return  {
                ...state,
                isJanusCall: action.payload
            }
        case IS_NODE_DISCONNECTED:
            return {
                ...state,
                isNodeDisconnected: action.payload
            }
        case MESSAGES_UPDATE:
            return  {
                ...state,
                messages: action.payload
            }
        case ATTACHMENTS_UPDATE:
            return  {
                ...state,
                attachments: action.payload
            }
        case REVIEW_ISSUES:
            return  {
                ...state,
                reviewIssues: action.payload
            }
        case CALL_ID:
            return {
                ...state,
                callId: action.payload
            }
        default:
            return state
    }
}
