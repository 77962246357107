import React, {Component} from "react";
import {IProps, IState} from "./types";
import I18n from "i18next";
import {sendTyping} from "../../utils/api/send";
import uploadIcon from "../../assets/img/upload.svg"
import sendIcon from "../../assets/img/send-icon.svg"
import FetchUpload from "../../utils/api/upload"
import * as Notification from '../Notification';
import moment from "moment";
import calculateDate from "../../utils/date/date";
import {withTranslation} from "react-i18next";

class InputBar extends Component <IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            onSubmit: props.onSubmit,
            sendTyping: props.sendTyping,
            remoteUser: props.remoteUser,
            authUser: props.authUser,
            progress: 0,
            isUpload: false,
            lockedBeforeHour: true,
            room: props.room
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.upload = this.upload.bind(this);
        this.renderUploadControl = this.renderUploadControl.bind(this);
    }

    componentDidMount(): void {
        let message_input = document.getElementById("messageInput");
        if (message_input) {
            message_input.focus();
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.room !== this.state.room) {
            this.setState({
                room: this.props.room,
                remoteUser: this.props.remoteUser,
                authUser: this.props.authUser
            })
        }

        if (this.props.room?.startAt) {
            const startAt = moment(calculateDate(this.props.room?.startAt))
            const start = startAt.subtract(1, 'hour')
            const now = moment(calculateDate(null))
            if (now < start && !this.state.lockedBeforeHour) {
                this.setState({lockedBeforeHour: true})
            } else if (now >= start && this.state.lockedBeforeHour) {
                this.setState({lockedBeforeHour: false})
            }
        }

        if (this.state.token !== this.props.jwtToken) {
            this.setState({token: this.props.jwtToken})
        }
    }

    render() {
        return (<>
            <form style={{display: "none"}}><input type="file" id="fileInput" onChange={this.upload}/></form>
            <form className="messages-bar" onSubmit={this.handleSubmit}>
                <div className="d-flex">
                    {this.renderUploadControl()}
                    <input
                        id="messageInput"
                        className="messages-bar-input"
                        placeholder={this.getPlaceholder()}
                        onChange={sendTyping}
                        autoComplete="off"
                        disabled={this.roomIsDisabled() || this.props.isNodeDisconnected ? true : undefined}
                    />
                    {(!this.roomIsDisabled() || this.props.isNodeDisconnected) && <img
                        onClick={this.handleSubmit}
                        className="messages-bar-sendBtn"
                        src={sendIcon}
                        alt=""
                    />}

                    {(this.roomIsDisabled() || this.props.isNodeDisconnected) && <img
                        className="messages-bar-sendBtn  messages-bar-uploadBtn-disabled"
                        src={sendIcon}
                        alt=""
                    />}
                </div>
            </form>
        </>)
    }

    renderUploadControl() {
        if (this.roomIsDisabled() || this.props.isNodeDisconnected) {
            return (
                <div className="messages-bar-uploadBtn messages-bar-uploadBtn-disabled" id="uploadBtn">
                    <img src={uploadIcon} alt=""
                         className="messages-bar-uploadBtn-image"/>
                </div>
            )
        }

        if (!this.state.isUpload) {
            return (
                <div className="messages-bar-uploadBtn" id="uploadBtn">
                    <img src={uploadIcon} onClick={this.handleUpload} alt=""
                         className="messages-bar-uploadBtn-image"/>
                </div>
            );
        }

        return (
            <div className="messages-bar-progress"
                 style={(this.state.progress === 100 ?
                         {color: "#28a745"} : {color: "#007bff"}
                 )}
            >
                <p>{this.state.progress}%</p>
            </div>
        )
    }

    handleSubmit(e: any) {
        e.preventDefault();
        let input = document.getElementById('messageInput');
        if (input) {
            // @ts-ignore
            let value = input.value;
            this.state.onSubmit(value);
            // @ts-ignore
            input.value = '';
        }
    };

    handleUpload() {
        let input = document.getElementById('fileInput');
        // @ts-ignore
        input.click();
    }

    upload(e: any) {
        let data: any;
        data = new FormData();

        if (!e.target.files[0]) return;

        data.append('file', e.target.files[0]);
        FetchUpload(
            String(this.state.token),
            this.state.room,
            this.state.authUser,
            data,
            (loaded: number, total: number) => {
                let percent = Math.round(Number(loaded * 100 / total));
                this.setState({
                    progress: percent,
                    isUpload: true
                }, () => {
                    if (percent === 100) {
                        setTimeout(() => {
                            this.setState({
                                isUpload: false,
                                progress: 0,
                            })
                            // @ts-ignore
                            document.getElementById('fileInput').value = null;
                        }, 3000)
                    }
                });
            },
            (error: any) => {
                Notification.error(error.message);
            }
        );
    };

    getPlaceholder() {

        if (!this.roomIsDisabled()) {
            return I18n.t('inputBar:placeholder')
        }

        if (!this.state.lockedBeforeHour) {
            return I18n.t('inputBar:placeholderBlocked')
        }

        // @ts-ignore
        const startAt = moment(calculateDate(this.props.room?.startAt))
        const start = startAt.subtract(1, 'hour')
        const now = moment(calculateDate(null))

        if (now.format('YYYY-MM-DD') === start.format('YYYY-MM-DD')) {
            return I18n.t('inputBar:placeholderBefore') + ' ' + I18n.t('inputBar:placeholderBeforeHour') + ' ' + start.format('HH:mm')
        }

        return I18n.t('inputBar:placeholderBefore') + ' '
            + I18n.t('inputBar:placeholderBeforeDay') + ' ' + start.format('YYYY-MM-DD') + ' '
            + I18n.t('inputBar:placeholderBeforeHour') + ' ' + start.format('HH:mm')

    }

    roomIsDisabled() {

        if (this.state.lockedBeforeHour) {
            return true;
        }

        return this.props.room && this.props.room.closed;
    }
}

export default withTranslation()(InputBar);
