import React, {Component} from "react";
import {IProps, IState} from "./types";
import MessageItem from "../MessageItem";
import {REDUCER_TYPE} from "../../modules/Reducer";
import {connect} from "react-redux";

class MessageList extends Component<IProps, IState> {
    private endRef?: HTMLDivElement | null;

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (this.state?.messages !== this.props.messages) {
            this.setState({messages: this.props.messages})
        }

        if (this.state?.authUuid !== this.props.state.authUser?.uuid) {
            this.setState({authUuid: String(this.props.state.authUser?.uuid)})
        }
    }

    render() {
        if (!this.state?.messages) return null;

        return (
            <div className="messages-list" id="messagesList">
                {
                    this.state.messages && Object.keys(this.state.messages).map((item: any, key: any) => {
                        // @ts-ignore
                        return <MessageItem message={this.state.messages[item]}
                            key={key}
                            myUuid={this.state.authUuid}
                        />
                    })
                }
                <div ref={ref => this.endRef = ref}/>
            </div>
        )
    }
}

const mapStateToProps = (state: REDUCER_TYPE) => {
    return {
        state: state
    }
}

export default connect(mapStateToProps)(MessageList);
