import {applyMiddleware, compose, createStore} from 'redux'
import thunk from 'redux-thunk'
import Reducer from "./Reducer";

// @ts-ignore
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default () => {
    return createStore(
        Reducer,
        compose(applyMiddleware(thunk))
    )
}
