import React, {Component} from 'react';
import {IProps, IState} from "./types";
import {disconnected, getUsersConfiguration, videoStop, videoStart} from "../../utils/api/subscribe";
import {setAttemptingCall} from "../../utils/head";
import {sendCloseVideo, sendMicMuted, sendMicUnmute, sendVideoFullScreen, sendVideoMimize, sendVideoStart, sendVideoStop, setUserConfiguration} from "../../utils/api/send";
import {CallPlaceholder} from "../../components/CallPlaceholder";
import CallControl from "../../components/CallControl";
import VideoSound from "../../components/SoundsController/VideoSound";
import VideoRoom from "../../utils/janus/1.1.2/videoroom";
import {connect} from "react-redux";
import {REDUCER_TYPE} from "../../modules/Reducer";
import {withTranslation} from "react-i18next";
import {IsJanusCall} from "../../modules/Actions";

class Video extends Component <IProps, IState> {

    protected VideoController: any;
    protected myCanvas: any;
    protected myVideo: any;
    protected clientCanvas: any;
    protected clientVideo: any;
    protected clientAudio: any;
    protected clientVideoDiv: any;

    protected videoRoom: any;

    constructor(props: IProps) {
        super(props);

        this.state = {
            isCall: false,
            isPictureInPicture: false,
            isFullScreen: false,
            isMute: false,
            isBlank: false,
            isAttemptingCall: false,
            playCallSound: false,
            disableBlank: false,
            disableMute: false,
            callWithSocket: false,
            remoteUserNoCam: true,
            isReconnecting: false,
            useFrontCamera: true,
            setReconnect: props.setReconnect,
            setCallState: props.setCallState,
            cameras: [],
            currentUsedCamera: null
        }
    }

    watchEvents = () => {
        const self = this

        window.addEventListener('ice.state', (event: any) => {
            const {state, type} = event.detail.data
            if (type === 'remote') {
                return
            }

            if (state === 'disconnected' && self.state.isCall) {
                self.setState({
                    isReconnecting: true,
                    remoteUserNoCam: false
                }, () => {
                    self.state.setReconnect(this.state.isReconnecting);
                });
            }

            if (state === 'connected' && self.state.isReconnecting && self.state.isCall) {
                self.setState({isReconnecting: false}, () => {
                    setTimeout(() => {
                        self.state.setReconnect(this.state.isReconnecting);
                    }, 1000)
                });
            }
        })

        window.addEventListener('janus.join',(event: any) => {
            if (self.state.playCallSound) {
                self.setState({playCallSound: false});
                setAttemptingCall(false);
            }
            this.setState({callWithSocket: event.detail.remoteSocket})
            this.videoRoom.initValues({
                window: window,
                navigator: navigator,
                RTCRtpTransceiver: RTCRtpTransceiver,
                document: document,
                username: this.props.authUser?.name,
                localVideo: this.myVideo,
                remoteVideo: this.clientVideo,
                remoteAudio: this.clientAudio
            })
            self.videoRoom.joinRoom(event.detail.janusUrl, event.detail.janusId, event.detail.janusToken, event.detail.janusRoomToken)
            self.controlVideo();
            this.props.dispatch(IsJanusCall(true))
        });

        window.addEventListener('janus.video', (event: any) => {
            setTimeout(() => {
                self.setState({remoteUserNoCam: false})
                if (this.clientVideo.paused) {
                    this.clientVideo.play()
                }
            }, 1000)
        })

        window.addEventListener('janus.audio', (event: any) => {
            setTimeout(() => {
                if (this.clientAudio.pasued) {
                    this.clientAudio.play()
                }
            }, 1000)
        })

        window.addEventListener('janus.stop', () => {
            self.controlClose(1);
        })

        window.addEventListener('video.reject',(event:any) => {
            self.controlClose(event.detail.data);
        });

        window.addEventListener('video.attemptCall',() => {
            self.setState({
                isAttemptingCall: true,
                playCallSound: true
            }, () => {
                setAttemptingCall(this.state.isAttemptingCall)
                self.setCallState(this.state.isCall, this.state.isAttemptingCall);
                setTimeout(() => {
                    self.setState({
                        playCallSound: false
                    }, () => {
                        setAttemptingCall(false);
                    })
                }, 15000)
            });
        });

        window.addEventListener('video.disconnected', function (event: any) {
            self.controlClose(event.detail.data);
        });

        videoStop(() => {
            self.setState({remoteUserNoCam: true})
        });

        videoStart(() => {
            self.setState({remoteUserNoCam: false})
        });
    }

    componentDidMount() {
        this.myCanvas = this.refs.myCanvas;
        this.myVideo = this.refs.myVideo;
        this.clientCanvas = this.refs.clientCanvas;
        this.clientVideo = this.refs.clientVideo;
        this.clientAudio = this.refs.clientAudio;
        this.clientVideoDiv = this.refs.clientVideoDiv;
        disconnected(this.onDisconnected);
        getUsersConfiguration(this.setConfiguration);
        this.watchEvents();

        const checkVideoSetup = setInterval(() => {
            if (this.myVideo.srcObject) {
                this.detectCameras();
                clearInterval(checkVideoSetup);
            }
        }, 500);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.authUser !== this.state.authUser) this.setState({authUser: this.props.authUser})
        if (this.props.remoteUser !== this.state.remoteUser) this.setState({remoteUser: this.props.remoteUser})
        if (this.state.loggedData !== this.props.loggedData) this.setState({loggedData: this.props.loggedData})
        if (!this.state.isCall && this.state.isCall !== prevState.isCall) this.setState({remoteUserNoCam: true, isReconnecting: false}, () => {this.state.setReconnect(this.state.isReconnecting);})
        if (this.props.VideoController && !prevProps.VideoController) {
            this.VideoController = this.props.VideoController
            this.videoRoom = new VideoRoom(this.VideoController)
        }
    }

    onDisconnected = (data:any) => {
        if (this.state.callWithSocket !== data.socketId) return;
        if (this.videoRoom._janus) {
            this.videoRoom.destroyJanus();
            this.setState({
                isCall: false,
                isAttemptingCall: false,
                isReconnecting: false,
            }, () => {
                this.setCallState(this.state.isCall,this.state.isAttemptingCall);
                this.state.setReconnect(this.state.isReconnecting);
            });
        }

        let alert = document.getElementById('react-confirm-alert');
        if (alert) {
            alert.remove();
        }
    }


    setCallState = (isCall:boolean, isAttemptingCall:boolean) => {
        this.state.setCallState(
            isCall,
            isAttemptingCall
        )
    }

    setConfiguration = (socketId:string, data: any) => {
        if (!this.state.isCall && this.state.callWithSocket) return true;
        if (!data) return;

        let connectionSocket: boolean | string = false;
        Object.keys(data).forEach((remoteId: string) => {
            let configuration = data[remoteId];
            if (socketId !== remoteId && this.state.authUser?.uuid !== configuration.participant_uuid) {
                if (configuration.isCall) connectionSocket = remoteId;
            }
        });

        if (connectionSocket) this.setState({callWithSocket: connectionSocket})
    }

    controlClose = (data: any) => {
        if (this.videoRoom._janus) {
            this.videoRoom.destroyJanus()
            // if (this.videoRoom.mystream) this.videoRoom.mystream.getTracks().forEach((track: any) => track.stop());
            //@ts-ignore
            if (this.state.isPictureInPicture) document.exitPictureInPicture();
            if (this.state.isFullScreen || document.fullscreenElement) document.exitFullscreen().then(() => {
                console.log('exit fullscreen')
            });

            this.setState({
                isCall: false,
                isAttemptingCall: false,
                isPictureInPicture: false,
                isFullScreen: false,
                isReconnecting: false,
            }, () => {
               this.setCallState(this.state.isCall,this.state.isAttemptingCall);
               this.state.setReconnect(this.state.isReconnecting);
               this.props.dispatch(IsJanusCall(false))
            });
        }

        if (data.participant && this.state.authUser?.uuid === data.participant.uuid && data.reason === '3') {
            this.setState({
                isAttemptingCall: false,
                isReconnecting: false,
            }, () => {
                this.setCallState(this.state.isCall, this.state.isAttemptingCall);
                this.state.setReconnect(this.state.isReconnecting);
            });
        }

        if (data.reason === '4' || data.reason === '1') {
            this.setState({
                isAttemptingCall: false,
                isCall: false,
            }, () => {
                this.setCallState(this.state.isCall,this.state.isAttemptingCall)
            });
        }

        this.setState({playCallSound: false});

        if (data.closeAnyway) {
            if (this.VideoController.LocalStream) this.VideoController.LocalStream.getTracks().forEach((track: any) => track.stop());

            this.setState({
                isAttemptingCall: false,
                isCall: false,
            }, () => {
                this.setCallState(this.state.isCall,this.state.isAttemptingCall)
            });
        }

        let alert = document.getElementById('react-confirm-alert');
        if (alert) {
            alert.remove();
        }
        setAttemptingCall(false);
        this.setState({
            isAttemptingCall: false,
            isCall: false,
        }, () => {
            this.setCallState(this.state.isCall,this.state.isAttemptingCall)
        });
    }

    controlVideo = () => {
        setTimeout(() => {
            this.setState({
                isAttemptingCall: false,
                isCall: true,
                isBlank: false,
                isMute: false
            }, () => {
                this.listenPictureInPictureEvents();
                this.listenFullScreenEvents();
                // this.checkEnableDevices();
                this.setCallState(this.state.isCall,this.state.isAttemptingCall)
                setUserConfiguration(
                    this.state.authUser?.uuid ? this.state.authUser.uuid : '',
                    true,
                    true,
                    true
                );
            })
        }, 300)
    };

    renderVideo = (stream: any, canvas: any, video: any) => {
        canvas.width = 0;
        canvas.height = 160;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        video.srcObject = stream;
        canvas.width = 0;
    };

    checkEnableDevices = () => {
        if (!this.VideoController.LocalStream.getVideoTracks().length) {
            this.setState({
                disableBlank: true,
                isBlank: true,
            });
        }

        if (!this.VideoController.LocalStream.getAudioTracks().length) {
            this.setState({
                disableMute: true,
                isMute: true,
            })
        }
    }

    fullScreenVideo = (e: any) => {
        e.preventDefault();
        const video = this.clientVideoDiv;
        try {
            if (document.fullscreenElement) {
                document.exitFullscreen().then(() => {
                    console.log('exit fullscreen');
                });
                sendVideoMimize();
                this.setState({isFullScreen: false})
                return;
            }

            if (video.requestFullscreen) video.requestFullscreen();
            else if (video.webkitRequestFullscreen) video.webkitRequestFullscreen();
            else if (video.mozRequestFullScreen) video.mozRequestFullScreen();
            else if (video.msRequestFullscreen) video.msRequestFullscreen();
            sendVideoFullScreen();
            this.setState({isFullScreen: true})
        } catch (e) {
            console.log(e);
        }
    };

    listenFullScreenEvents = () => {
        const $this = this;
        this.clientVideoDiv.addEventListener('fullscreenchange', () => {
            if (document.fullscreenElement) {
                $this.setState({isFullScreen: true})
            } else {
                this.setState({isFullScreen: false})
            }
        })
    };

    pictureInPictureVideo = (e: any) => {
        e.preventDefault();
        try {
            if (!this.state.isPictureInPicture) {
                this.clientVideo.requestPictureInPicture();
                this.setState({isPictureInPicture: true});
            } else {
                // @ts-ignore
                document.exitPictureInPicture();
                this.setState({isPictureInPicture: false});
            }
        } catch (e) {
            console.log(e);
        }
    };

    listenPictureInPictureEvents = () => {
        const $this = this;
        this.clientVideo.addEventListener('enterpictureinpicture', function () {
            $this.setState({isPictureInPicture: true});
        });

        this.clientVideo.addEventListener('leavepictureinpicture', function () {
            $this.setState({isPictureInPicture: false});
            if (document.getElementById('client-video')) {
                setTimeout(() => {
                    // @ts-ignore
                    document.getElementById('client-video').play()
                }, 100)
            }
        });
    };

    toggleStreamVoice = (e: any) => {
        e.preventDefault();
        const muted = this.videoRoom.toggleMute()
        if (muted) {
            this.setState({isMute: true}, () => {
                sendMicMuted();
            })
        } else {
            this.setState({isMute: false}, () => {
                sendMicUnmute();
            })
        }
    };

    toggleStreamVideo = (e: any) => {
        e.preventDefault();
        for (const i in this.videoRoom.localTracks) {
            const stream = this.videoRoom.localTracks[i];
            if ((stream.getVideoTracks()[0].enabled)) {
                this.setState({isBlank: true}, () => {
                    sendVideoStop();
                })
            } else {
                this.setState({isBlank: false}, () => {
                    sendVideoStart();
                })
            }
            stream.getVideoTracks()[0].enabled = !(stream.getVideoTracks()[0].enabled);
        }
    };

    endVideo = (e: any) => {
        e.preventDefault();
        if (!this.videoRoom._janus) {
            return false;
        }

        this.controlClose({
            reason: "2"
        });

        sendCloseVideo();
    };

    toggleCamera = (e: any): void => {
        e.preventDefault();

        const currentUseFrontCamera = this.state.useFrontCamera;
        const filteredDevicesIndex = currentUseFrontCamera ? this.state.cameras.length - 1 : 0;

        this.setState({ currentUsedCamera: this.state.cameras[filteredDevicesIndex] }, () => {
            if (this.state.currentUsedCamera) {
                this.setupVideoStream(this.state.currentUsedCamera.deviceId);
            }
        });

        this.setState(prevState => ({
            useFrontCamera: !prevState.useFrontCamera
        }));
    };

    changeCamera = (e: any, camera: MediaDeviceInfo): void => {
        e.preventDefault();

        this.setState({ currentUsedCamera: camera }, () => {
            this.setupVideoStream(camera.deviceId);
        });
    };

    setupVideoStream = (deviceId: string): void => {
        try {
            for (const i in this.videoRoom.localTracks) {
                try {
                    const stream = this.videoRoom.localTracks[i];
                    stream.getVideoTracks().forEach((track: { stop: () => any; }) => track.stop());
                } catch (err) {
                    console.log("Error stopping stream:", err);
                }
            }

            this.videoRoom.sfutest.replaceTracks({
                tracks: [
                    {
                        type: 'video',
                        mid: '1',
                        capture: { deviceId: { exact: deviceId } }
                    }
                ]
            });
        } catch (error) {
            console.error('Error during setupVideoStream:', error);
        }
    };

    detectCameras = (): void => {
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                const videoInputs = devices.filter(device => device.kind === 'videoinput');
                const currentVideoDevice = this.myVideo.srcObject.getVideoTracks()[0];
                const currentVideoInput = devices.filter(device => device.label === currentVideoDevice.label)[0];

                this.setState({ cameras: videoInputs, currentUsedCamera: currentVideoInput });
            })
            .catch(err => console.error('Error accessing devices:', err));
    };

    render() {
        return (<div style={(!this.state.isCall || this.state.isReconnecting ? {display: "none"} : {height: "100%"})}>
            <div
                ref='clientVideoDiv'
                id="clientVideoDiv"
                className={this.state.remoteUserNoCam ? 'video-client video-client-noCam' : 'video-client'}
            >
                <video ref='clientVideo' id="client-video"
                       width={'100%'}
                       height={'100%'}
                       playsInline
                       autoPlay
                       loop
                       controls={false}
                       preload={'yes'}
                       style={(this.state.remoteUserNoCam ? {display: "none"} : (this.state.isFullScreen ? {width: "100%"} : {}))}
                />
                <audio ref='clientAudio' id="clientAudio" autoPlay playsInline style={{display: "none"}}/>
                {this.state.remoteUserNoCam && <CallPlaceholder remoteUser={this.state.remoteUser}/>}
                <CallControl
                    fullScreenVideo={this.fullScreenVideo}
                    pictureInPictureVideo={this.pictureInPictureVideo}
                    toggleStreamVoice={this.toggleStreamVoice}
                    toggleStreamVideo={this.toggleStreamVideo}
                    endVideo={this.endVideo}
                    isFullScreen={this.state.isFullScreen}
                    isPictureInPicture={this.state.isPictureInPicture}
                    isMute={this.state.isMute}
                    isBlank={this.state.isBlank}
                    disableBlank={this.state.disableBlank}
                    disableMute={this.state.disableMute}
                    remoteUserNoCam={this.state.remoteUserNoCam}
                    toggleCamera={this.toggleCamera}
                    cameras={this.state.cameras}
                    currentUsedCamera={this.state.currentUsedCamera}
                    changeCamera={this.changeCamera}
                />
            </div>
            <div className="video-my">
                <video
                    muted={true}
                    ref='myVideo'
                    id="my-video"
                    controls={false}
                    playsInline autoPlay
                    style={{
                        height: "100%",
                        ...(this.state.isBlank ? {display: "none"} : {})
                    }}
                />
            </div>
            <VideoSound
                playMessage={false}
                playCall={this.state.playCallSound}
            />
        </div>)
    }
}

const mapStateToProps = (state: REDUCER_TYPE) => {
    return state
}

export default withTranslation()(connect(mapStateToProps)(Video));
