

class Media {

    protected Navigator: any;
    protected Stream: any = null;

    protected CameraId:any;
    protected MicId:any;
    protected AudioOptions: any;
    protected VideoOptions: any;
    protected AudioInput: any;
    protected VideoInput: any;

    setNavigator = (navigator:any) => {
        this.Navigator = navigator;
    };

    getNavigator = () => {
        return this.Navigator;
    };

    setCamId = (camId: string) => {
        this.CameraId = camId;
        return this.CameraId;
    };

    getCamId = () => {
        return this.CameraId;
    };

    setMicId = (micId: string) => {
        this.MicId = micId;
        return this.MicId;
    };

    getMicId = () => {
        return this.MicId;
    };

    setStream = (stream:any) => {
        this.Stream = stream;
        return this.Stream;
    };

    getStream = async () => {
        if (!this.Stream) {
            await this.createStream().then((stream: any) => {
                return stream;
            })
        }

        return this.Stream;
    };

    clearStream = () => {
        this.Stream = null;
    }


    constructor(navigator:any, loggedData:any) {
        this.prepareNavigator(navigator);
        this.setCamId(localStorage['camId'] ?? '');
        this.setMicId(localStorage['micId'] ?? '');
        this.clearStream();
    }

    prepareNavigator = (navigator:any) => {
        if (navigator.mediaDevices === undefined) {
            navigator.mediaDevices = {};
        }

        if (navigator.mediaDevices.getUserMedia === undefined) {
            navigator.mediaDevices.getUserMedia = function (constraints: any) {
                let getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;

                if (!getUserMedia) {
                    return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
                }

                return new Promise(function (resolve, reject) {
                    getUserMedia.call(navigator, constraints, resolve, reject);
                });
            }
        }

        this.setNavigator(navigator);
    };

    prepareInputOptions = (deviceId: string, chosenDeviceId: string) => {
        let results = {};
        if (chosenDeviceId !== '' && deviceId !== chosenDeviceId) {
            results = {
                deviceId: chosenDeviceId,
                facingMode: "environment"
            }
        }
        return results;
    };

    createStream =  () => {
        return new Promise((resolve, reject) => {
            const $this = this;
            let videoInput = false;
            let videoOptions = {};
            let audioInput = false;
            let audioOptions = {};

            $this.getNavigator().mediaDevices.enumerateDevices().then((devices: any) => {
                for (let i in devices) {
                    if (devices[i].kind === 'videoinput') {
                        videoOptions = $this.prepareInputOptions(devices[i].deviceId, $this.getCamId());
                        videoInput = true
                    }
                    if (devices[i].kind === 'audioinput') {
                        audioOptions = $this.prepareInputOptions(devices[i].deviceId, $this.getMicId());
                        audioInput = true
                    }
                }

                $this.getNavigator().mediaDevices.getUserMedia({
                    video: Object.keys(videoOptions).length ? videoOptions : videoInput,
                    audio: Object.keys(audioOptions).length ? audioOptions : audioInput
                }).then((stream: any) => {
                    $this.setStream(stream);
                    resolve(stream);
                }).catch((err: any) => {
                    reject(err);

                });
            }).catch((err: any) => {
                $this.getNavigator().mediaDevices.getUserMedia({
                    video: Object.keys(videoOptions).length ? videoOptions : videoInput,
                    audio: Object.keys(audioOptions).length ? audioOptions : audioInput,
                }).then((stream: any) => {
                    $this.setStream(stream);
                    resolve(stream);
                }).catch((err: any) => {
                    reject(err);
                });
            });


        });
    };



}

export default Media;
