import {
    ATTACHMENTS_UPDATE,
    AUTHORIZATION_SUCCESS, CALL_ID,
    IS_JANUS_CALL, IS_NODE_DISCONNECTED,
    LOGGED_DATA_UPDATE,
    MESSAGES_UPDATE, REVIEW_ISSUES
} from "./Types";

export const AuthorizationSuccess = (payload: object) => ({
    type: AUTHORIZATION_SUCCESS,
    payload
})

export const LoggedDataUpdate = (payload: object) => ({
    type: LOGGED_DATA_UPDATE,
    payload
})

export const JanusRoomIdUpdate = (payload: string) => ({
    type: LOGGED_DATA_UPDATE,
    payload
})

export const MessagesUpdate  = (payload: any) => ({
    type: MESSAGES_UPDATE,
    payload
})

export const AttachmentsUpdate  = (payload: any) => ({
    type: ATTACHMENTS_UPDATE,
    payload
})

export const IsJanusCall = (payload: boolean) => ({
    type: IS_JANUS_CALL,
    payload
})

export const IsNodeDisconnected = (payload: boolean) => ({
    type: IS_NODE_DISCONNECTED,
    payload
})

export const ReviewIssues = (payload:{audio: string[], video: string[]}) => ({
    type: REVIEW_ISSUES,
    payload
})

export const CallId = (payload: number) => ({
    type: CALL_ID,
    payload
})

// export const expertsAction = () => {
//     return async (dispatch, getState) => {
//         const state = getState()
//         const api = new Api()
//         expertsRequest()
//         api.get('experts', {
//             user_id: state.page.user.uuid,
//             page_id: state.page.page.uuid,
//         }).then((response) => {
//             if (Array.isArray(response.users)) {
//                 dispatch(expertsSuccess(response.users))
//             }
//         }).catch(e => {
//             expertsError(e)
//         })
//     }
// }
