import React, {Component} from 'react';
import {IProps, IState} from "./types";
import I18n from "i18next";
import {connected, disconnected, chatDuration} from "../../utils/api/subscribe";
import {setRoomDuration} from "../../utils/api/send";
import {connect} from "react-redux";
import {REDUCER_TYPE} from "../../modules/Reducer";
import {withTranslation} from "react-i18next";

class StopWatch extends Component <IProps, IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            seconds: 0,
            minutes: 0,
            hours: 0,
            start: false,
            defaultDuration: 0,
            remoteUserActive: false
        }
    }

    componentDidMount(): void {
        connected((data: any) => {
            let seconds = this.calculateToSeconds();
            setTimeout(() => {
                setRoomDuration(seconds);
            }, 500);
        });

        disconnected((data: any) => {
            if (!this.state.start) {
                return;
            }

            let seconds = this.calculateToSeconds();
            setTimeout(() => {
                setRoomDuration(seconds);
            }, 500);
        });


        chatDuration((data: any) => {
            let seconds = this.calculateToSeconds()

            if (data.duration > (seconds + 10) || data.duration < (seconds + 10)) {
                this.calculateFromSeconds(data.duration);
            }
        })
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (this.props.room && this.state.defaultDuration !== this.props.room?.duration) {
            this.setState({
                defaultDuration: this.props.room?.duration
            }, () => {
                this.calculateFromSeconds(this.state.defaultDuration ? this.state.defaultDuration : 0);
            })
        }

        if (this.props.loggedData && this.props.loggedData.remoteUserActive !== this.state.remoteUserActive) {
            this.setState({remoteUserActive: this.props.loggedData.remoteUserActive}, () => {
                if (this.state.remoteUserActive) {
                    this.start();
                } else {
                    this.stop()
                }
            })
        }
    }

    add = () => {
        let seconds = this.state.seconds;
        let minutes = this.state.minutes;
        let hours = this.state.hours;
        seconds++;
        if (seconds >= 60) {
            seconds = 0;
            minutes++;
            if (minutes >= 60) {
                minutes = 0;
                hours++;
            }
        }

        this.setState({
            seconds: seconds,
            minutes: minutes,
            hours: hours
        }, () => {
            this.timer()
        });
    };

    timer = () => {
        if (!this.state.start) {
            return false
        }
        setTimeout(this.add, 1000);
    };

    start = () => {
        this.setState({
            start: true
        }, () => {
            this.timer()
        });
    };


    stop = () => {
        this.setState({
            start: false
        });
    };

    calculateFromSeconds = (timestamp: number) => {
        let hours = Math.floor(timestamp / 60 / 60);
        let minutes = Math.floor(timestamp / 60) - (hours * 60);
        let seconds = timestamp % 60;

        this.setState({
            hours: hours,
            minutes: minutes,
            seconds: seconds
        });
    };

    calculateToSeconds = () => {
        let hours = this.state.hours;
        let minutes = this.state.minutes;
        let seconds = this.state.seconds;
        minutes = minutes + (hours * 60);
        seconds = seconds + (minutes * 60);
        return seconds
    }

    render() {
        return (<>
            <p className="stopwatch-counter">
                <span>
                    {I18n.t('app:activity')}:&nbsp;
                </span>
                {
                    this.state.hours > 0
                    && (this.state.hours > 9 ? this.state.hours : '0' + this.state.hours) + ':'
                }
                {
                    this.state.hours > 0 || this.state.minutes > 0
                        ? (this.state.minutes > 9 ? this.state.minutes : '0' + this.state.minutes) + '' : '00'
                }
                <span>
                {
                    this.state.seconds > 0
                        ? (this.state.seconds > 9 ? ':' + this.state.seconds : ':0' + this.state.seconds) : ':00'
                }
                </span>
            </p>
        </>)
    }
}

const mapStateToProps = (state: REDUCER_TYPE) => {
    return state
}

export default withTranslation()(connect(mapStateToProps)(StopWatch));
