import React, {useState} from "react";
import {IProps} from "./types";
// import I18n from "i18next";
// import startCall from "../../assets/img/startcall.svg"
// import cog from "../../assets/img/disable-cog.png"
// import VisitInfo from '../VisitInfo';
// import {REDUCER_TYPE} from "../../modules/Reducer";
// import {connect} from "react-redux";
// import {withTranslation} from "react-i18next";

const Bitrate = (props: IProps) => {

    const [bitrate, setBitrate] = useState(false);

    window.addEventListener('janus.bitrate', (event: any) => {
        setBitrate(event.detail.bitrate)
    })

    const formatBitrate = (btr:string|boolean) => {
        if (typeof btr === "boolean") {
            return 0;
        }

        return Number(btr.replace(/\D/g, ""));
    }
    if (!bitrate && formatBitrate(bitrate) <= 0) {
        return null;
    }
    // 875 kbits/sec

    return (
        <div style={{
            "position": "absolute",
            'left': '10px',
            'bottom': '5px',
            'fontSize': '10px',
            'opacity': 0.2,
            'color': (formatBitrate(bitrate) < 200 ? '#e95498' : '#fff')
        }}>
            {bitrate}
        </div>
    )
}

export default Bitrate
