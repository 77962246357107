import React from "react";
import i18n from "i18next";
import * as Subscribe from "../api/subscribe";
import {sendRejectCall, sendRejectSelfCall, sendAnswer, sendCallWith} from "../api/send";
import {confirmAlert} from "react-confirm-alert";

class VideoController {

    LoggedData = null
    AuthUser = null
    RemoteUser = null
    Room = null
    socketId = ''
    janusId = ''

    constructor(authUser, remoteUser, loggedData, room, socketId) {
        this.LoggedData = loggedData;
        this.AuthUser = authUser;
        this.RemoteUser = remoteUser;
        this.Room = room;
        this.socketId = socketId
        this.watchCall();
        this.watchAnswer()
        this.watchVideoCallClose();
        this.watchVideoCallInProgress()
    }

    updateLoggedData = (loggedData) => {
        this.LoggedData = loggedData
    }

    watchAnswer = () => {
        const self = this
        Subscribe.onVideoAnswer((data) => {
            self.janusId = data.janusId


            const joinEvent = new CustomEvent('janus.join', {
                detail: {
                    janusId: self.janusId,
                    janusToken: data.janusToken,
                    janusUrl: data.janusUrl,
                    janusRoomToken: data.janusRoomToken,
                    remoteSocket: data.responderId
                }
            });

            window.dispatchEvent(joinEvent);

            sendCallWith(data.responderId ?? '')
        })
    }

    watchCall = () => {
        const self = this;
        Subscribe.onVideoCall((data) => {
            if (self.AuthUser && self.AuthUser.uuid !== data.participant.uuid && self.LoggedData?.authUserConf) {
                // self.clearTimeouts();
                self.janusId = data.janusId

                const handleAnswer = () => {
                    sendRejectSelfCall(data.callerId);
                    sendAnswer(data.callerId)
                    const renderEvent = new CustomEvent('janus.join', {
                        detail: {
                            janusId: self.janusId,
                            janusToken: data.janusToken,
                            janusUrl: data.janusUrl,
                            janusRoomToken: data.janusRoomToken,
                            remoteSocket: data.callerId
                        }
                    });

                    window.dispatchEvent(renderEvent);
                };

                const handleReject = () => {
                    sendRejectCall(data.callerId);
                    const rejectEvent = new CustomEvent('video.reject', {
                        detail: {data: data}
                    });
                    window.dispatchEvent(rejectEvent);
                };

                const attemptCall = new CustomEvent('video.attemptCall');
                window.dispatchEvent(attemptCall);

                confirmAlert({
                    customUI: ({onClose}) => {
                        return (<div className='custom-alert'>
                            <p><b>{self.RemoteUser.name}</b> {i18n.t('video:incomingCall')}</p>
                            <div>
                                <button className="btn btn-success" onClick={() => {
                                    handleAnswer();
                                    onClose();
                                }}>{i18n.t('video:answer')}</button>
                                <button className="btn btn-secondary" onClick={() => {
                                    handleReject();
                                    onClose()
                                }}>{i18n.t('video:reject')}</button>
                            </div>
                        </div>);
                    },
                    onClickOutside: () => {
                        handleReject();
                    },
                    onKeypressEscape: () => {
                        handleReject();
                    },
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                });
            }
        });
    };

    watchVideoCallClose = () => {
        Subscribe.closeVideo((data) => {
            const disconnected = new CustomEvent('video.disconnected', {
                detail: {data: data}
            });
            window.dispatchEvent(disconnected);
            if (data.clearCallWith) {
                sendCallWith(null)
            }
        })
    }

    watchVideoCallInProgress = () => {
        Subscribe.videoCallInProgress((data) => {
            this.callInProgress(i18n.t('video:callInProgres'), null);
        });
    }

    callInProgress = (message, connection) => {
        const self = this;
        const acceptAlert = (message, connection) => {
            if (connection) return connection.close();
            const rejectEvent = new CustomEvent('video.reject', {
                detail: {
                    data: {
                        socketId: self.socketId,
                        closeAnyway: true,
                    }
                }
            });
            window.dispatchEvent(rejectEvent);
        }

        setTimeout(() => {
            let alert = document.getElementById('react-confirm-alert');
            if (alert) {
                alert.remove();
                acceptAlert(message, connection);
            }
        }, 10000)
        confirmAlert({
            title: '',
            message: message,
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => {
                        acceptAlert(message, connection)
                    }
                }
            ]
        })
    };

    // watchDisconnected = () => {
    //     Subscribe.disconnected((data) => {
    //         if (this.state.callWithSocket !== data.socketId) return;
    //         if (this.VideoController.getPeer() && this.VideoController.getPeerCall()) {
    //             this.VideoController.peerDestroy();
    //             if (this.VideoController.LocalStream) this.VideoController.LocalStream.getTracks().forEach((track: any) => track.stop());
    //             this.setState({
    //                 isCall: false,
    //                 isAttemptingCall: false,
    //                 isReconnecting: false,
    //             }, () => {
    //                 this.setCallState(this.state.isCall,this.state.isAttemptingCall);
    //                 this.state.setReconnect(this.state.isReconnecting);
    //             });
    //         }
    //
    //         let alert = document.getElementById('react-confirm-alert');
    //         if (alert) {
    //             alert.remove();
    //         }
    //     })
    // }
}

export default VideoController
