import parse from 'html-react-parser';
import {
    closeVideo,
    connected,
    disconnected, micMuted, micUnmute, onError,
    videoFullscreen,
    videoMinimize,
    videoStart,
    videoStop
} from "../../utils/api/subscribe";
import I18n from "i18next";
import {Participant} from "../../types";


let Container: any;
const autoDismiss = 3;
const position = "tc";

export const style = {
    NotificationItem: {
        DefaultStyle: {
            margin: '10px 5px 2px 1px'
        },

        info: {
            fontFamily: "Lato",
            background: "#474747",
            borderRadius: 7,
            color: "#FFFFFF",
            borderTop: "none",
            padding: 15,
            fontSize: 16,
        },
        error : {
            fontFamily: "Lato",
            background: 'rgb(233, 85, 152)',
            borderRadius: 7,
            color: "#FFFFFF",
            borderTop: "none",
            padding: 15,
            fontSize: 16,
        }
    }
};

export const setContainer = (container: any) => {
    Container = container;
};

const prepareNotification = () => {
    return Container.current;
}

export const info = (message: any) => {
    let current = prepareNotification();
    if (!current) {
        return false;
    }

    let msg = parse (message);
    current.clearNotifications();
    current.addNotification({
        position: position,
        autoDismiss: autoDismiss,
        level: "info",
        message: msg,
    })
};

export const error = (message: any) => {
    let current = prepareNotification();
    if (!current) {
        return false;
    }
    let msg = parse (message);
    current.clearNotifications();
    current.addNotification({
        position: position,
        autoDismiss: autoDismiss,
        level: "error",
        message: msg,
    })
};

export const watch = (authUser: Participant | null) => {
    connected((data: any) => {
        info(data.participant.name + ': ' + I18n.t('notifications:connected'));
    });

    disconnected((data: any) => {
        info(data.participant.name + ': ' + I18n.t('notifications:disconnected'));
    });

    videoStart((data: any) => {
        info(data.participant.name + ': ' + I18n.t('notifications:videoStart'));
    });

    videoStop((data: any) => {
        info(data.participant.name + ': ' + I18n.t('notifications:videoStop'));
    });

    videoMinimize((data: any) => {
        info(data.participant.name + ': ' + I18n.t('notifications:videoMinimize'));
    });

    videoFullscreen((data: any) => {
        info(data.participant.name + ': ' + I18n.t('notifications:videoFullscreen'));
    });

    micMuted((data: any) => {
        info(data.participant.name + ': ' + I18n.t('notifications:micMuted'));
    });

    micUnmute((data: any) => {
        info(data.participant.name + ': ' + I18n.t('notifications:micUnmute'));
    });

    closeVideo((data: any) => {
        if (authUser && data.participant.uuid === authUser.uuid) {
            const confirmAlert = document.getElementById('react-confirm-alert')
            if (confirmAlert) {
                confirmAlert.remove();
            }
        } else {
            if (data.reason === '1') {
                info(data.participant.name + ': ' + I18n.t('notifications:rejectVideo'));
            } else if (data.reason === '2') {
                info(data.participant.name + ': ' + I18n.t('notifications:closeVideo'));
            }
        }
    })
}



