import React, {Component} from 'react';
import {IProps, IState} from "./types";
import Sidebar from "../../containers/Sidebar";
import Chat from "../../containers/Chat";
import MessageSound from "../../components/SoundsController/MessageSound";
import {chatMessage, chatMessageUpdate} from "../../utils/api/subscribe";
import * as Notification from '../../components/Notification'
import * as Scroll from 'react-scroll';
import {prepareAttachment} from "../../controllers/attachment";
import moment from "moment";
import I18n from "i18next";
import {connect} from "react-redux";
import {REDUCER_TYPE} from "../../modules/Reducer";
import {AttachmentsUpdate, MessagesUpdate} from "../../modules/Actions";
import {withTranslation} from "react-i18next";

// eslint-disable-next-line @typescript-eslint/no-unused-vars

class Authorized extends Component <IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            chosenModule: 'video',
            channelId: props.channelId,
            messages: props.messages,
            attachments: props.attachments||null,
            loggedData: props.loggedData,
            newMessage: false,
        };

        this.catchNewMessage();
        this.catchUpdateMessage();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.messages !== prevProps.messages || this.props.attachments !== prevProps.attachments || this.props.loggedData !== prevProps.loggedData) {
            this.setState({
                messages: this.props.messages,
                attachments: this.props.attachments || null,
                loggedData: this.props.loggedData,
            })
        }
    }

    changeModule = (value: string) => {
        this.setState({
            chosenModule: value
        });

        let message_input = document.getElementById("messageInput");

        setTimeout(() => {
            this.scrollMessageList();
        }, 300);

        if (message_input) {
            message_input.focus();
        }
    };

    catchNewMessage = () => {
        chatMessage((data: any) => {
            this.addMessage(data.message);
            this.controlNotifications(data.message);

            if (data.message.participant.uuid === this.state.authUser?.uuid) {
                if (this.state.authUser?.role === 'User' && !this.state.loggedData.remoteUserActive) {
                    const message = {
                        content: I18n.t('warnings:doctorNotPresentWarning'),
                        createdAt: moment().format('YYYY-MM-DD HH:mm')
                    }
                    this.addMessage(message);
                    this.controlNotifications(data.message);
                    Notification.info(message.content)
                }
            }
        });
    };

    controlNotifications = (message: any) => {
        let list = document.getElementById('messagesList');
        if (list) {
            let height = list.scrollHeight - list.clientHeight;
            if (height - list.scrollTop > 300) {
                Notification.info(message.participant.name + ': ' + message.content)
            } else {
                this.scrollMessageList();
            }
        }

        if (this.state.authUser && message.participant.uuid !== this.state.authUser?.uuid) {
            this.setState({newMessage: true}, () => {
                setTimeout(() => {
                    this.setState({newMessage: false})
                }, 1000)
            })
        }
    };

    scrollMessageList = () => {
        Scroll.animateScroll.scrollToBottom({
            containerId: "messagesList",
            duration: 200,
        })
    };

    addMessage = (message: object) => {
        let messages: any[] = [];
        if (this.state.messages) {
            messages = this.state.messages;
        }

        // @ts-ignore
        const createdAt = message.createdAt;

        if (createdAt) {
            const updatedCreated = moment(createdAt).add('2', "hour");
            message = {
                ...message,
                createdAt: updatedCreated.format()
            }
        }


        messages.push(message);
        this.setState({
            messages: messages
        });
        this.props.dispatch(MessagesUpdate(messages))
        this.addAttachment(message);
        return false;
    };

    addAttachment = (message: any) => {
        if (!message.attachment) {
            return false;
        }

        let attachments: any[] = [];
        if (this.state.attachments) {
            attachments = this.state.attachments;
        }

        let attachment = prepareAttachment(message);

        attachments.push(attachment);

        this.setState({attachments: attachments});
        this.props.dispatch(AttachmentsUpdate(attachments))
    };

    catchUpdateMessage = () => {
        chatMessageUpdate((data: any) => {
            setTimeout(() => {
                this.updateMessage(data.message)
            }, 1000)
        })
    };

    updateMessage = (message: any) => {
        let messages = this.state.messages;
        messages?.map((item, key) => {
            if (item.uuid === message.uuid) {
                // @ts-ignore
                messages[key] = message;
            }
        });
        this.props.dispatch(MessagesUpdate(messages))
        this.setState({messages: messages});
    };

    render() {
        return (<div className="authorized clear-space-sm">
            <Sidebar
                chosenModule={this.state.chosenModule}
                changeModule={this.changeModule}
                newMessage={this.state.newMessage}
            />
            <Chat
                messages={this.state.messages}
                chosenModule={this.state.chosenModule}
            />
            <MessageSound
                playMessage={this.state.newMessage}
                playCall={false}
            />
        </div>)
    }

}

const mapStateToProps = (state: REDUCER_TYPE) => {
    return state
}

// @ts-ignore
export default withTranslation()(connect(mapStateToProps)(Authorized))

