import React, {Component} from 'react';
import {IProps, IState, Languages, chosenLanguage} from './types'
import I18n from "i18next";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

class ChangeLanguage extends Component<IProps, IState> {

    constructor(props:IProps) {
        super(props);

        this._change = this._change.bind(this);

        let browser_lang =  navigator.language.split('-')[0];
        let chosen_lang = Languages.filter(function (item) {
            return item.value === browser_lang
        })
        let lang = (chosen_lang.length ? chosen_lang[0] : Languages[0]);
        this.state = {
            chosenLanguage: lang
        }

        I18n.changeLanguage(lang.value)
    }

    _change = (lang:chosenLanguage) => {
        I18n.changeLanguage(lang.value).then(t => {
            this.setState({
                chosenLanguage: lang
            })
        });
    }

    render () {
        return (<div className="change-language">
            <Dropdown className='dropdown-language'  options={Languages} onChange={(lang:any) => {this._change(lang)}} value={this.state.chosenLanguage.label ? this.state.chosenLanguage.label : ''} />
        </div>)
    }

}
export default ChangeLanguage;

