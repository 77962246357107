import React, {Component} from 'react'
import {IState, IProps} from "./types";
import times from '../../assets/img/ic_times.png'
import I18n from "i18next";
import parse from 'html-react-parser';
import {withTranslation} from "react-i18next";

class ManualModal extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            isVisible: props.isVisible,
            onHide: props.onHide
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (this.state.isVisible !== this.props.isVisible) {
            this.setState({
                isVisible: this.props.isVisible
            })
        }
    }

    createManual = () => {
        let list: any[] = [];

        for (let i = 1; i < 17; i++) {
            let element = (<li key={i}>
                <strong>{I18n.t('help:listTitle' + i)}</strong><br/>
                -&nbsp;{parse(I18n.t('help:listDesc' + i))}
            </li>);

            list.push(element);
        }
        return list;
    }

    renderModal = () => {
        return (<div className="manual-modal">
            <div className="manual-modal-header">
                <p>{I18n.t('help:title')}</p>
                <div className="manual-modal-header-close" onClick={this.state.onHide}><img alt={''} src={times}/></div>
            </div>

            <div className="manual-modal-content">
                <p>
                    <strong>
                        {I18n.t('help:subtitle')}
                    </strong>
                </p>


                <ol>
                    {this.createManual()}
                </ol>
            </div>
        </div>)
    }

    render() {
        return (
            this.state.isVisible ? this.renderModal() : <></>
        )
    }
}

export default withTranslation()(ManualModal);
