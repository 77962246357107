import axios from 'axios';
const URL = process.env.REACT_APP_MEDCONNECT + '/review/'

export const getIssues = async (token: string) => {
    return axios.request({
        method:"get",
        url: URL + 'issues?bearer=' + token,
    }).then(response => {
        return response.data;
    });
};

export const send = async (token: string, room: string, participant: string, data: {
    callId: number,
    rate: number,
    audioIssues: string[],
    videoIssues: string[],
    audioIssueDescription: string | null,
    videoIssueDescription: string | null,
    userAgent: string
}) => {
    return axios.request({
        method:"post",
        url: URL + 'create/room/' + room + '/participant/' + participant + '?bearer=' + token,
        data: data
    }).then(response => {
        return response.data;
    });
};