import React, {Component} from 'react';
import {IProps, IState} from "./types";
import {chatTyping} from "../../utils/api/subscribe";
import {sendMessage, sendTyping} from "../../utils/api/send";
import * as Scroll from 'react-scroll';
import * as Notifications from '../../components/Notification'
import MessageList from "../../components/MessageList";
import TypingPlaceholder from "../../components/TypingPlaceholder";
import InputBar from "../../components/InputBar"
import {connect} from "react-redux";
import {REDUCER_TYPE} from "../../modules/Reducer";

class MessageContainer extends Component <IProps, IState> {
    protected messageList: any;

    constructor(props: IProps) {
        super(props);

        this.state = {
            sendingTyping: false,
            messages: this.props.messages,
            tabClass: props.tabClass,
        };

        this.catchMessageTyping();
    }

    componentDidMount(): void {
        setTimeout(() => {
            this.scrollListToBottom();
        }, 200)
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (this.state.tabClass !== this.props.tabClass) {
            this.setState({tabClass: this.props.tabClass})
        }

        if (!prevProps.state.authUser && this.props.state.authUser) {
            Notifications.watch(this.props.state.authUser);
        }

        if (this.state.messages !== this.props.messages) {
            this.setState({
                messages: this.props.messages
            })
        }
    }

    handleSubmit = (value: string) => {
        if (!value) return;
        sendMessage(value);
    };

    sendTyping = () => {
        if (this.state.sendingTyping) return false;

        this.setState({
            sendingTyping: true
        }, () => {
            sendTyping();
            setTimeout(() => {
                this.setState({
                    sendingTyping: false
                })
            }, 500)
        })
    }

    catchMessageTyping = () => {
        chatTyping((data: any) => {
            if (this.state.isTyping) return true;

            this.setState({
                isTyping: true,
                whoTyping: data.participant,
            }, () => {
                setTimeout(() => {
                    this.setState({
                        isTyping: false,
                        whoTyping: undefined,
                    })
                }, 3000)
            })
        });
    };

    scrollListToBottom = () => {
        Scroll.animateScroll.scrollToBottom({
            containerId: "messagesList",
            duration: 200,
        })
    };

    renderList = () => {
        return <MessageList
            isTyping={this.state.isTyping}
            whoTyping={this.state.whoTyping}
            messages={this.state.messages}
        />
    }

    render() {
        return (
            <div className={this.state.tabClass}>
                <div className="messages" ref={list => {
                    this.messageList = list
                }}>
                    {this.renderList()}
                    <div className="messages-bar-container">
                        <TypingPlaceholder
                            whoTyping={this.state.whoTyping}
                            isTyping={this.state.isTyping}
                        />
                        <InputBar
                            onSubmit={(value: string) => {
                                this.handleSubmit(value)
                            }}
                            sendTyping={this.sendTyping}
                            room={this.props.state.room}
                            remoteUser={this.props.state.remoteUser}
                            authUser={this.props.state.authUser}
                            jwtToken={this.props.state.jwtToken}
                            isNodeDisconnected={this.props.state.isNodeDisconnected}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: REDUCER_TYPE) => {
    return {
        state: state
    }
}

// @ts-ignore
export default connect(mapStateToProps)(MessageContainer);
