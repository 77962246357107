import React, {useState} from 'react';
import I18n from "i18next";
import {onError} from "../../utils/api/subscribe";
import CSS from 'csstype';

const ErrorIcon = (props: any) => {
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState("");
    const [videoIssue, setVideoIssue] = useState(true);
    const [errorType, setErrorType] = useState("");
    const remoteFeedIssue = 'remoteFeedIssue';
    const ourFeedIssue = 'ourFeedIssue';

    const style: {container: CSS.Properties, videoIssue: CSS.Properties, errorIcon: CSS.Properties} = {
        container: {
            position: 'absolute',
            bottom: 0,
            width: '95%',
            height: '20px'
        },
        videoIssue: {
            fontSize: '12px',
            position: 'absolute',
            right: '5%',
            width: '90%',
            textAlign: 'center',
            color: '#fcfcfc',
            background: '#363636',
            opacity: 0.7,
            borderRadius: '50px',
            padding: '5px',
            bottom: 0
        },
        errorIcon: {
            color: '#f44336',
            borderWidth: '1px',
            borderColor: '#F44336',
            borderStyle: 'solid',
            borderRadius: '50px',
            paddingRight: '8px',
            paddingLeft: '8px',
            cursor: 'pointer',
            position: 'absolute',
            right: '0px',
            fontSize: '12px'
        }
    }

    window.addEventListener('janus.videoIssue', (event: any) => {
        const errorType = event.detail.type;
        setErrorType(errorType);
        if (errorType === remoteFeedIssue || errorType === ourFeedIssue) {
            setVideoIssue(true);
        } else {
            setVideoIssue(false);
        }
    });

    window.addEventListener('janus.error', (event: any) => {
        const error = event.detail.data;
        if (error === 'janus_error') {
            setError(I18n.t('video:failed') + ' [ ' + error + ' ]');
        } else {
            setError(I18n.t('video:failed') + ' [ ' + I18n.t('video:janus_error')  + ' ]')
        }

        setShowError(true);

        setTimeout(() => {
            setShowError(false)
            setError("")
        }, 10000)
    })

    onError((data: any) => {
        let er = ''
        if (data.error === 'janus_error') {
            er = I18n.t('video:failed') + ' [ ' + I18n.t('video:janus_error')  + ' ]';
        } else {
            er = I18n.t('video:failed') + ' [ ' + data.error + ' ]';
        }
        setError(er);
        setShowError(true);

        setTimeout(() => {
            setShowError(false)
            setError("")
        }, 10000)
    });

    const showErrorIcon = () => {
        if (!showError) {
            return null;
        }

        return <div style={style.errorIcon} title={error}>
            <strong>!</strong>
        </div>
    }

    const showVideoIssue = () => {
        if (!videoIssue) {
            return null;
        }
        return <div style={style.videoIssue}>{errorType === remoteFeedIssue || errorType === ourFeedIssue ? I18n.t('video:' + errorType) : ''}</div>
    }

    if (!props.isCall) {
        return null;
    }

    if (!showError && !videoIssue) {
        return null;
    }

    return (
        <div style={style.container}>
            {showVideoIssue()}
            {showErrorIcon()}
        </div>
    )
}

export default ErrorIcon