import React, {Component} from 'react';
import {IProps, IState} from "./types";
import SidebarList from "../../components/SidebarList";
import ManualModal from "../../components/ManualModal";
import {ModuleValues} from "../../types"
import {setIsNewMessage} from "../../utils/head";

class Sidebar extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            chosenModule: props.chosenModule,
            manualModal: false,
            changeModule: props.changeModule,
            newMessage: props.newMessage,
            faviconMessage: false,
        }
    }

    componentDidMount(): void {
        this.handleDocumentFocus();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.chosenModule !== this.props.chosenModule) {
            this.setState({chosenModule: this.props.chosenModule})
        }

        if (!document.hasFocus() && !this.state.faviconMessage && this.props.newMessage) {
            this.setState({
                faviconMessage: true
            }, () => {
                setIsNewMessage(this.state.faviconMessage);
            });
        }

        if (this.state.newMessage !== this.props.newMessage && !this.state.newMessage) {
            let messageContainer = document.getElementById('messagesList');
            if (
                messageContainer
                && !(messageContainer.offsetWidth
                    || messageContainer.offsetHeight
                    || messageContainer.getClientRects().length
                )
            ) {
                this.setState({newMessage: this.props.newMessage});
            }
        }
    }

    handleDocumentFocus = () => {
        setInterval(() => {
            if (!this.state.faviconMessage) {
                return true;
            }
            if (document.hasFocus()) {
                this.setState({
                    faviconMessage: false
                }, () => {
                    setIsNewMessage(this.state.faviconMessage);
                })
            }
        }, 200)
    };

    handleChangeModule = (value: any) => {
        if (value === ModuleValues.HELP) {
            this.showManualModal();
            return true;
        }

        if (this.state.manualModal && value === ModuleValues.HELP) {
            this.hideManualModal();
            return true;
        }

        if (value === ModuleValues.CHAT && this.state.newMessage) {
            this.setState({newMessage:false})
        }

        this.state.changeModule(value)
    };

    showManualModal = () => {
        if (!this.state.manualModal) {
            this.setState({manualModal: true})
        }
    };

    hideManualModal = () => {
        if (this.state.manualModal) {
            this.setState({manualModal: false})
        }
    };

    render() {
        return (<>
            <div className="sidebar">
                <ul className="sidebar-list">
                    <SidebarList
                        changeModule={(value:any) => {this.handleChangeModule(value)}}
                        chosenModule={this.state.chosenModule}
                        isNewMessage={this.state.newMessage}
                    />
                </ul>
            </div>
            <ManualModal
                isVisible={this.state.manualModal}
                onHide={this.hideManualModal}
            />
        </>)
    }
}

export default Sidebar;



