import {io} from "./index";


export const connected = (output: void | any) => {
    io.on('connected', (data: any) => {
        output(data);
        return false;
    });
};

export const authorized = (output: void | any) => {
    io.on('authorized', (data: any) => {
        output(data);
        return false;
    });
};

export const chatMessage = (output: void | any) => {
    io.on('chat.message', (data: any) => {
        output(data);
        return false;
    });
};

export const chatTyping = (output: void | any) => {
    io.on('chat.typing', (data: any) => {
        output(data);
        return false;
    });
};

export const onVideoCall = (output: void|any) => {
    io.on('video.call', (data: any) => {
        output(data);
    })
}

export const onVideoAnswer = (output: void|any) => {
    io.on('video.answer', (data: any) => {
        output(data);
    })
}

export const onError = (output: void|any) => {
    io.on('janus.error', (data: any) => {
        output(data);
    })
}

export const videoStart = (output: void | any) => {
    io.on('video.start', (data: any) => {
        output(data);
        return false;
    });
};

export const videoStop = (output: void | any) => {
    io.on('video.stop', (data: any) => {
        output(data);
        return false;
    });
};

export const videoMinimize = (output: void | any) => {
    io.on('video.minimize', (data: any) => {
        output(data);
        return false;
    });
};

export const videoFullscreen = (output: void | any) => {
    io.on('video.fullscreen', (data: any) => {
        output(data);
        return false;
    });
};

export const micMuted = (output: void | any) => {
    io.on('mic.muted', (data: any) => {
        output(data);
        return false;
    });
};

export const micUnmute = (output: void | any) => {
    io.on('mic.unmuted', (data: any) => {
        output(data);
        return false;
    });
};

export const disconnected = (output: void | any) => {
    io.on('disconnected', (data: any) => {
        output(data);
        return false;
    });
};

export const closeVideo = (output: void | any) => {
    io.on('video.disconnected', (data: any) => {
        output(data);
        return false
    })
};

export const chatMessageUpdate = (output:void|any) => {
    io.on('chat.messageUpdate', (data:any) => {
        output(data);
        return false;
    })
};

export const videoCallInProgress = (output:void|any) => {
    io.on('video.callInProgress', (data:any) => {
        output(data);
        return false;
    })
};

export const errorConnect = (output:any) => {
    io.on('connect_error', (data:any ) => {
        output(data);
    })
}

export const disconnect = (output: any) => {
    io.on('disconnect', (data:any) => {
        output(data);
    })
}

export const chatDuration = (output: any) => {
    io.on('chat.duration', (data: any) => {
        output(data);
    })
}

export const getUsersConfiguration = (output:any) => {
    io.on('chat.userSettingsChanges', (data:any) => {
        output(io.id, data);
    })
}

export const onCallWith = (output: any) => {
    io.on('video.callWith', (data:any) => {output(data)});
}

export const videoCallId = (output: (callId: number) => void) => {
    io.on('video.callId', (data: {callId: number}) => {
        output(data.callId)
    })
}
