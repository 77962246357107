import moment from "moment-timezone";

export default function calculateDate(datestring:string|null) {
    moment.tz.setDefault('Europe/Warsaw');

    if (!datestring) {
        return moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD HH:mm:ss')
    }

    return  moment(datestring).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD HH:mm')
}
